import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/css'

import { TENANT } from '../../config'
import { useTenantConfig } from '../../lib/providers/tenantConfig'
import { RemoteContent } from '../../components/Content'
import { ReceiptDetail, ReceiptList } from '../../components/Receipts'
import Header from '../../components/Header'
import { getReceipt, getReceipts, Receipt } from '../../lib/api'
import { useRemote } from '../../lib/hooks/remote'
import { toDate, hasPayment, paymentAmount } from '../../lib/utils'

export const MyMobilepayTrialsPage: React.FC = () => {
  const tenantConfig = useTenantConfig()

  const fetchTrials = useCallback(() => {
    return getReceipts({ debit: true })
  }, [])

  const { data: receipts, error, fetch } = useRemote<Receipt[]>(fetchTrials)
  const [trialReceipts, setTrialReceipts] = useState<Receipt[]>([])

  useEffect(() => {
    if (!receipts) {
      return
    }

    const trialReceipts = filterPendingMobilePay(receipts)
    setTrialReceipts(trialReceipts)
  }, [receipts])

  return (
    <IonPage>
      <IonHeader>
        <Header>{tenantConfig?.pages.my.mobilepay.label}</Header>
      </IonHeader>
      <IonContent className="my my-mobile-pay" fullscreen>
        {tenantConfig?.pages.my.mobilepay.header && (
          <img
            src={`/assets/tenants/${TENANT}/images/header-my-mobilepay.jpg`}
            alt=""
          />
        )}
        <div
          className={
            'ion-padding ' +
            css`
              h1:first-child {
                margin-top: 0;
              }
            `
          }
        >
          <h1>{tenantConfig?.pages.my.mobilepay.label}</h1>
          <p>
            Folgende MobilePAY Zahlungen werden
            <span
              className={css`
                background-color: #f0f0f0;
                padding: 4px;
                margin: 2px;
                vertical-align: middle;
                font-size: 11px;
                color: var(--ion-color-tertiary);
              `}
            >
              demnächst
            </span>
            von Ihrem Konto abgebucht.
          </p>
        </div>
        <RemoteContent onRetry={fetch} error={error}>
          {trialReceipts && (
            <ReceiptList
              receipts={trialReceipts}
              itemLink={(receipt) => `/my/mobilepay/${receipt.receiptNumber}`}
              itemSum={(receipt) => paymentAmount(receipt, 'Jost Mobile Pay')}
              sum={true}
              withdrawalDate={true}
              notFoundMessage="Derzeit stehen keine Abbuchungen an."
            />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}

export const MobilePayTrialsShowPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const fetchReceipt = useCallback(() => {
    return getReceipt(id)
  }, [id])

  const { data: receipt, error, fetch } = useRemote<Receipt>(fetchReceipt)

  return (
    <IonPage>
      <IonHeader>
        <Header>
          {receipt && `Zahlung vom ${toDate(receipt.date, 'DD.MM.')}`}
        </Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <RemoteContent onRetry={fetch} error={error}>
          {receipt && (
            <ReceiptDetail
              receipt={receipt}
              label="Auswahl"
              barcode={true}
              fields={{
                payment: true,
              }}
            />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}

function filterPendingMobilePay(receipts: Receipt[]): Receipt[] {
  return receipts
    .filter((receipt) => hasPayment(receipt, 'Jost Mobile Pay'))
    .filter((receipt) => receipt.pendingDebit)
}
