import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useState, useCallback, useEffect, MouseEventHandler } from 'react'
import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'
import { useHistory, useParams } from 'react-router-dom'
import { sanitize } from 'dompurify'
import { TENANT, API } from '../config'
import { Page, getPagesCached } from '../lib/api'
import Header from '../components/Header'

const StaticPage: React.FC = () => {
  const history = useHistory()
  const { slug } = useParams<{ slug: string }>()
  const [page, setPage] = useState<Page>({} as Page)
  const [version, setVersion] = useState<string>('0.0.0')

  const fetch = useCallback(async () => {
    const pages = await getPagesCached()
    const currentPage = findPageBySlug(pages, slug)
    if (currentPage) {
      setPage(currentPage)
      addStylesheet(`${API}/assets/${TENANT}/styles.css`)
    }
  }, [slug])

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      App.getInfo().then((info) => {
        setVersion(info.version)
      })
    }
  })

  const onStaticClick: MouseEventHandler = (evt) => {
    const target = evt.target as HTMLElement
    const link = target.closest('[data-router-link]') as HTMLElement
    if (link && link.dataset['routerLink']) {
      const path = link.dataset['routerLink']
      if (path) {
        history.push(path)
      }
      evt.preventDefault()
    }
  }

  const parseHtmlBody = (html: string) => {
    if (!html) {
      return ''
    }

    const substituteVariables = (html: string) => {
      html = html.replace(/\$\$API\$\$/g, API)
      html = html.replace(/\$\$VERSION\$\$/g, version)
      return html
    }

    const substitutedBody = substituteVariables(html)

    return sanitize(substitutedBody, {
      ADD_TAGS: ['ion-icon'],
    })
  }

  return (
    <IonPage>
      <IonHeader>
        <Header>{page.title}</Header>
      </IonHeader>
      <IonContent fullscreen>
        <div
          onClickCapture={onStaticClick}
          dangerouslySetInnerHTML={{
            __html: parseHtmlBody(page.body),
          }}
        ></div>
      </IonContent>
    </IonPage>
  )
}

function findPageBySlug(pages: Page[], slug: string) {
  return pages.find((page) => page.slug === slug)
}

function addStylesheet(url: string) {
  const hasStylesheet = (url: string) =>
    document.querySelector(`link[href='${url}]'`)

  if (!hasStylesheet(url)) {
    const link = document.createElement('link')
    link.setAttribute('href', url)
    link.setAttribute('rel', 'stylesheet')
    link.setAttribute('type', 'text/css')
    document.head.appendChild(link)
  }
}

export default StaticPage
