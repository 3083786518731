import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react'
import { css } from '@emotion/css'
import { RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { TENANT } from '../config'
import { getEvents, Event } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const EventsPage: React.FC<{ menu?: boolean }> = ({ menu = true }) => {
  const { data: events, error, fetch } = useRemote<Event[]>(getEvents)
  const tenantConfig = useTenantConfig()

  return (
    <>
      {menu && <Menu />}
      <IonPage id="main-content">
        <IonHeader>
          <Header menu={menu} back={!tenantConfig?.tabs.events.enabled}>
            {tenantConfig?.tabs.events.label}
          </Header>
        </IonHeader>
        <IonContent className="events" fullscreen>
          <img
            src={
              TENANT === 'cj'
                ? 'https://cdn.cjschmidt.de/app/public/img/header-events.jpg'
                : `/assets/tenants/${TENANT}/images/header-events.jpg`
            }
            alt="Events Header"
          />
          <div className="ion-padding-horizontal ion-padding-top">
            {TENANT === 'cj' && <h1>{tenantConfig?.tabs.events.label}</h1>}
            <div className="events">
              <IonList lines="full">
                <RemoteContent onRetry={fetch} error={error}>
                  {events && events.length > 0 ? (
                    <>
                      {events.map((events) => (
                        <EventItem event={events} key={events.title} />
                      ))}
                    </>
                  ) : (
                    <div className="ion-padding-horizontal ion-padding-top empty">
                      <IonText>
                        Zur Zeit stehen leider noch keine Termine fest.
                      </IonText>
                    </div>
                  )}
                </RemoteContent>
              </IonList>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

const EventItem: React.FC<{ event: Event }> = ({ event }) => {
  const tenantConfig = useTenantConfig()
  const fullHref = event.href
    ? tenantConfig?.about.website + event.href
    : undefined

  return (
    <IonItem className={styles} href={fullHref} target="_blank">
      <IonLabel>
        <h2>{event.title}</h2>
        <p>{event.description}</p>
        <div
          className={css`
            padding-top: 5px;
          `}
        >
          <div className="location">{event.location}</div>
          <div className="date">{event.date}</div>
        </div>
      </IonLabel>
    </IonItem>
  )
}

const styles = css`
  h2,
  p {
    white-space: pre-wrap;
    color: var(--ion-color-primary);
  }
`

export default EventsPage
