import { useCallback, useEffect } from 'react'
import {
  IonPage,
  IonHeader,
  IonContent,
  useIonViewWillEnter,
} from '@ionic/react'
import { css } from '@emotion/css'
import debounce from 'lodash/debounce'

import { useTenantConfig } from '../../lib/providers/tenantConfig'
import Barcode from '../../components/Barcode'
import { RemoteContent, InnerMessage } from '../../components/Content'
import Header from '../../components/Header'
import { useUserConfig } from '../../lib/providers/userConfig'
import { getVouchers, Voucher, postVouchersRead } from '../../lib/api'
import { useRemote } from '../../lib/hooks/remote'
import { toDate, toEuro } from '../../lib/utils'
import { TENANT } from '../../config'

const MyVouchersPage: React.FC = () => {
  const tenantConfig = useTenantConfig()

  const fetchVouchers = useCallback(() => {
    return getVouchers()
  }, [])

  const { data: vouchers, fetch, error } = useRemote<Voucher[]>(fetchVouchers)
  const { userConfig, setUserConfig, userConfigLoading } = useUserConfig()

  const markAsRead = useCallback(async () => {
    if (userConfigLoading) {
      return
    }

    await postVouchersRead()

    setUserConfig((userConfig) => {
      return {
        ...userConfig,
        vouchers: {
          ...userConfig.vouchers,
          unread: false,
        },
      }
    })
  }, [userConfigLoading, setUserConfig])

  // When first visiting this page
  useEffect(() => {
    markAsRead()
  }, [markAsRead, userConfigLoading])

  // When revisiting this page
  useIonViewWillEnter(markAsRead, [userConfigLoading])

  // When interacting with this page (without revisiting)
  //
  // Invoke `markAsRead()` only once in given interval. Recreate bounced
  // function (effectively clearing the debounce) when the `userConfig` has
  // changed (e.g. when new voucher has come in)
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMarkAsRead = useCallback(
    debounce(markAsRead, 5000, { leading: true, trailing: false }),
    [markAsRead, userConfig]
  )

  return (
    <IonPage>
      <IonHeader>
        <Header>{tenantConfig?.pages.my.vouchers.label}</Header>
      </IonHeader>
      <IonContent
        className="my my-vouchers"
        fullscreen
        onClick={debouncedMarkAsRead}
        onTouchStart={debouncedMarkAsRead}
      >
        {tenantConfig?.pages.my.vouchers.header && (
          <img
            src={
              TENANT === 'cj'
                ? 'https://cdn.cjschmidt.de/app/public/img/header-my-vouchers.jpg'
                : `/assets/tenants/${TENANT}/images/header-my-vouchers.jpg`
            }
            alt=""
          />
        )}
        <div
          className={
            'ion-padding ' +
            css`
              h1:first-child {
                margin-top: 0;
              }
            `
          }
        >
          <h1>{tenantConfig?.pages.my.vouchers.label}</h1>
          <p>
            {TENANT === 'cj'
              ? 'Ihre Gutscheine können Sie in jedem unserer Modehäuser sowie im Home & Living und im WMF plus im THEO Shoppingcenter einlösen.'
              : 'Die nachfolgend aufgeführten Gutscheine können in jedem unserer Modehäuser eingelöst werden.'}
          </p>
        </div>
        <RemoteContent onRetry={fetch} error={error}>
          <div className="voucher-wrapper">
            {vouchers && vouchers.length > 0 ? (
              vouchers.map((voucher) => (
                <VoucherItem voucher={voucher} key={voucher.number} />
              ))
            ) : (
              <InnerMessage>
                Zurzeit sind keine offenen Gutscheine verfügbar.
              </InnerMessage>
            )}
          </div>
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}

const VoucherItem: React.FC<{ voucher: Voucher }> = ({ voucher }) => {
  return (
    // TODO: Move inline styles to app.scss
    <div
      className={
        'voucher ' +
        css`
          padding: var(--ion-padding);
          border-top: 1px solid var(--ion-color-primary);

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          h3 {
            font-size: 1.2rem;
            margin: 8px 0;
            color: var(--ion-color-primary);
            text-transform: none;
          }

          .details {
            margin: 8px 0;
            font-size: 0.8rem;
            color: var(--ion-color-medium-tint);
          }
        `
      }
    >
      <div className="row">
        <div>
          <h3>Nr. {voucher.number}</h3>
          <p className="details">
            {toDate(voucher.date)} | {displayType(voucher.type)}
            {voucher.validUntil && (
              <>
                <br />
                einzulösen bis {toDate(voucher.validUntil)}
              </>
            )}
          </p>
        </div>
        <div>
          <h3>
            <strong>{toEuro(voucher.value)}</strong>
          </h3>
        </div>
      </div>
      <Barcode value={String(voucher.number)} />
    </div>
  )
}

function displayType(type: string) {
  switch (TENANT) {
    case 'jost':
      return { birthday: 'Geburtstag', bonus: 'Bonus', other: 'Sonstiges' }[
        type
      ]
    case 'cj':
      return type
    default:
      return type
  }
}

export default MyVouchersPage
