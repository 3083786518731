import { createContext, useContext, useState, useEffect } from 'react'
import { loadTenantConfig, TenantConfig } from '../../config'

const tenantConfigContext = createContext<TenantConfig | undefined>(undefined)

export function useTenantConfig() {
  return useContext(tenantConfigContext)
}

function useProvideTenantConfig(): TenantConfig | undefined {
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>()

  useEffect(() => {
    loadTenantConfig().then((config) => {
      setTenantConfig(config)
    })
  })

  return tenantConfig
}

export function ProvideTenantConfig({
  children,
}: {
  children: React.ReactNode
}) {
  const tenantConfig = useProvideTenantConfig()
  return (
    <tenantConfigContext.Provider value={tenantConfig}>
      {children}
    </tenantConfigContext.Provider>
  )
}
