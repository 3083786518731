import { css } from '@emotion/css'
import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Barcode from '../components/Barcode'
import { RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { TENANT } from '../config'
import { getMe, Customer } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const BarcodePage: React.FC = () => {
  const { data: customer, error, fetch } = useRemote<Customer>(getMe)
  const tenantConfig = useTenantConfig()

  return (
    <>
      <Menu />
      <IonPage id="main-content">
        <IonHeader>
          <Header menu={true} back={!tenantConfig?.tabs.barcode.enabled}>
            {tenantConfig?.tabs.barcode.label}
          </Header>
        </IonHeader>
        <IonContent fullscreen className="barcode ion-padding">
          <div className={'ion-padding '}>
            <p>
              {TENANT === 'cj'
                ? 'Beim Shoppen mit Ihrer Vorteilskarte oder PremiumCARD erhalten Sie für jeden regulären Einkauf Bonus – zeigen Sie an der Kasse ganz einfach diesen Strichcode.'
                : ''}
            </p>
          </div>
          <RemoteContent onRetry={fetch} error={error}>
            {customer && (
              <>
                <div
                  className={css`
                    margin-top: 1rem;

                    @media (min-height: 610px) {
                      margin-top: 0rem;
                    }
                  `}
                >
                  <Barcode value={customer.customerCardNumber} />
                </div>

                <div
                  className={
                    'barcode-details ' +
                    css`
                      padding: var(--ion-padding);
                      margin: 0rem 0;

                      @media (min-height: 700px) {
                        margin: 3rem 0;
                      }

                      .label {
                        width: 50% !important;
                        text-transform: none !important;

                        @media (min-width: 375px) {
                          width: 45% !important;
                        }
                      }
                    `
                  }
                >
                  <table className="table col-2">
                    <tbody>
                      <tr>
                        <td className="label">Name</td>
                        <td className="data">{customer.name1}</td>
                      </tr>
                      <tr>
                        <td className="label">Kundennr.</td>
                        <td className="data">{customer.number}</td>
                      </tr>
                      {tenantConfig?.pages.my.trials.enabled && (
                        <tr>
                          <td className="label">Auswahlservice</td>
                          <td className="data">
                            {customer.mobilepayEnabled ? 'aktiv' : 'inaktiv'}
                          </td>
                        </tr>
                      )}
                      {tenantConfig?.pages.my.mobilepay.enabled && (
                        <tr>
                          <td className="label">MobilePAY</td>
                          <td className="data">
                            {customer.mobilepayEnabled ? 'aktiv' : 'inaktiv'}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </RemoteContent>
          <div
            className={css`
              position: absolute;
              left: 0;
              right: 0;
              bottom: 80px;

              img {
                display: block;
                width: 200px;
                margin: 0 auto;
                padding: 1rem;
                display: block;
              }
            `}
          >
            <img
              src={`/assets/tenants/${TENANT}/images/logo-full.png`}
              alt={tenantConfig?.about.name + ' Logo'}
            />
          </div>
        </IonContent>
      </IonPage>
    </>
  )
}

export default BarcodePage
