import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { css } from '@emotion/css'

import { TENANT } from '../../config'
import { useTenantConfig } from '../../lib/providers/tenantConfig'
import { RemoteContent } from '../../components/Content'
import { ReceiptDetail, ReceiptList } from '../../components/Receipts'
import Header from '../../components/Header'
import { getReceipt, getReceipts, Receipt } from '../../lib/api'
import { useRemote } from '../../lib/hooks/remote'
import { toDate } from '../../lib/utils'

export const MyReceiptsPage: React.FC = () => {
  const tenantConfig = useTenantConfig()

  const fetchReceipts = useCallback(() => {
    return getReceipts({ debit: false })
  }, [])

  const { data: receipts, error, fetch } = useRemote<Receipt[]>(fetchReceipts)

  return (
    <IonPage>
      <IonHeader>
        <Header>{tenantConfig?.pages.my.receipts.label}</Header>
      </IonHeader>
      <IonContent className="my my-receipts" fullscreen>
        {tenantConfig?.pages.my.receipts.header && (
          <img
            src={
              TENANT === 'cj'
                ? 'https://cdn.cjschmidt.de/app/public/img/header-my-receipts.jpg'
                : `/assets/tenants/${TENANT}/images/header-my-receipts.jpg`
            }
            alt=""
          />
        )}
        <div
          className={
            'ion-padding ' +
            css`
              h1:first-child {
                margin-top: 0;
              }
            `
          }
        >
          <h1>{tenantConfig?.pages.my.receipts.label}</h1>
          <p>
            Alle Einkaufsbelege Ihrer letzten Einkäufe – hiermit sind Rückgaben
            auch ohne Originalbeleg möglich.
          </p>
        </div>
        <div className="ion-padding-horizontal">
          <RemoteContent onRetry={fetch} error={error}>
            {receipts && (
              <ReceiptList
                receipts={receipts}
                itemLink={(receipt) => `/my/receipts/${receipt.receiptNumber}`}
              />
            )}
          </RemoteContent>
        </div>
      </IonContent>
    </IonPage>
  )
}

export const ReceiptsMyJostShowPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  const fetchReceipt = useCallback(() => {
    return getReceipt(id)
  }, [id])

  const { data: receipt, error, fetch } = useRemote<Receipt>(fetchReceipt)

  return (
    <IonPage>
      <IonHeader>
        <Header>
          {receipt && `Einkauf vom ${toDate(receipt.date, 'DD.MM.')}`}
        </Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <RemoteContent onRetry={fetch} error={error}>
          {receipt && (
            <ReceiptDetail
              receipt={receipt}
              label="Bon"
              barcode={true}
              fields={{
                time: true,
                totalQuantity: true,
                payment: true,
                staff: true,
              }}
            />
          )}
        </RemoteContent>
      </IonContent>
    </IonPage>
  )
}
