import { ReactNode } from 'react'
import { IonRouterOutlet, IonTabs } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect } from 'react-router-dom'
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from '@ionic/react'

import { useTenantConfig } from './lib/providers/tenantConfig'
import { SentryRoute as Route } from './sentry'
import { ProtectedRoute, useAuth } from './lib/providers/auth'
import ExternalRedirect from './components/ExternalRedirect'
import { VoucherBadge } from './components/VoucherBadge'
import * as icons from 'ionicons/icons'

/* Pages */
import Placeholder from './pages/Placeholder'
import NewsPage from './pages/News'
import MenusPage from './pages/Menus'
import MyPage from './pages/My'
import ProfilePage from './pages/Profile'
import MyBonuspointsPage from './pages/my/Bonuspoints'
import {
  MyMobilepayTrialsPage,
  MobilePayTrialsShowPage,
} from './pages/my/MobilepayTrials'
import { MyReceiptsPage, ReceiptsMyJostShowPage } from './pages/my/Receipts'
import { MyTrialsPage, TrialsMyJostShowPage } from './pages/my/Trials'
import MyVouchersPage from './pages/my/Vouchers'
import MyPersonalShopping from './pages/my/PersonalShopping'
import BarcodePage from './pages/Barcode'
import MobilepayPage from './pages/Mobilepay'
import VouchersPage from './pages/Vouchers'
import ShopPage from './pages/Shop'
import EventsPage from './pages/Events'
import StaticPage from './pages/Static'
import LoginPage from './pages/auth/Login'
import ActivatePage from './pages/auth/Activate'
import PasswordPage from './pages/Password'
import DeleteAccountPage from './pages/DeleteAccount'
import { RoutedApp } from './App'

// Conditionally wrap routes (given as children) in tabs. This is to workaround
// broken route animations. Ionic does not accept child elements of
// `<IonRouterOutler />` to be wrapped in a fragment (routes must be immediate
// children).
const TabRouter: React.FC<{ showTabs: boolean; tabBar: ReactNode }> = ({
  showTabs,
  tabBar,
  children,
}) => {
  return (
    <IonReactRouter>
      {showTabs ? (
        <IonTabs>
          <IonRouterOutlet>{children}</IonRouterOutlet>
          {tabBar}
        </IonTabs>
      ) : (
        <IonRouterOutlet>{children}</IonRouterOutlet>
      )}
      {/* Must be here for access to history provided by <IonReactRouter/> component */}
      <RoutedApp />
    </IonReactRouter>
  )
}

const Router: React.FC = () => {
  const auth = useAuth()
  const tenantConfig = useTenantConfig()
  const tabs = tenantConfig?.tabs

  // TODO: Rename tab identifiers to use more generic names
  const tabBar = (
    <IonTabBar class="tab-bar" slot="bottom">
      {tabs?.news.enabled && (
        <IonTabButton tab="home" href="/news">
          {<IonIcon icon={icons[tabs.news.icon as keyof typeof icons]} />}
          <IonLabel>{tabs.news.label}</IonLabel>
        </IonTabButton>
      )}
      {tabs?.menus.enabled && (
        <IonTabButton tab="home" href="/menus">
          <IonIcon icon={icons[tabs.menus.icon as keyof typeof icons]} />
          <IonLabel>{tabs.menus.label}</IonLabel>
        </IonTabButton>
      )}
      {tabs?.my.enabled && (
        <IonTabButton tab="myjost" href="/my">
          <IonIcon icon={icons[tabs.my.icon as keyof typeof icons]} />
          <IonLabel>{tabs.my.label}</IonLabel>
          <VoucherBadge />
        </IonTabButton>
      )}
      {tabs?.barcode.enabled && (
        <IonTabButton tab="barcode" href="/barcode">
          <IonIcon icon={icons[tabs.barcode.icon as keyof typeof icons]} />
          <IonLabel>{tabs.barcode.label}</IonLabel>
        </IonTabButton>
      )}
      {tabs?.mobilepay.enabled && (
        <IonTabButton tab="mobilepay" href="/mobilepay">
          <IonIcon icon={icons[tabs.mobilepay.icon as keyof typeof icons]} />
          <IonLabel>{tabs.mobilepay.label}</IonLabel>
        </IonTabButton>
      )}
      {tabs?.events.enabled && (
        <IonTabButton tab="events" href="/events">
          <IonIcon icon={icons[tabs.events.icon as keyof typeof icons]} />
          <IonLabel>{tabs.events.label}</IonLabel>
        </IonTabButton>
      )}
      {tabs?.vouchers.enabled && (
        <IonTabButton tab="vouchers" href="/vouchers">
          <IonIcon icon={icons[tabs.vouchers.icon as keyof typeof icons]} />
          <IonLabel>{tabs.vouchers.label}</IonLabel>
        </IonTabButton>
      )}
      {tabs?.shop.enabled && (
        <IonTabButton
          tab="shop"
          href={tabs.shop.externalOnly ? '/external-shop' : '/shop'}
        >
          <IonIcon icon={icons[tabs.shop.icon as keyof typeof icons]} />
          <IonLabel>{tabs.shop.label}</IonLabel>
        </IonTabButton>
      )}
    </IonTabBar>
  )

  // Before applying routes, wait until we know if the user is logged in
  // (depends on async read from storage). This prevents undesired flashes of
  // the login screen and simplifies routing (no need to redirect back after
  // login).
  return auth.initialized ? (
    <TabRouter {...{ showTabs: !!auth.user, tabBar }}>
      <ProtectedRoute path="/news" exact>
        <NewsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/menus" exact>
        <MenusPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my" exact>
        <MyPage />
      </ProtectedRoute>
      <Route path="/password" exact>
        <PasswordPage change={true} />
      </Route>
      <ProtectedRoute path="/profile" exact>
        <ProfilePage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/bonuspoints" exact>
        <MyBonuspointsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/receipts" exact>
        <MyReceiptsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/receipts/:id" exact>
        <ReceiptsMyJostShowPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/trials" exact>
        <MyTrialsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/trials/:id" exact>
        <TrialsMyJostShowPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/vouchers" exact>
        <MyVouchersPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/mobilepay" exact>
        <MyMobilepayTrialsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/mobilepay/:id" exact>
        <MobilePayTrialsShowPage />
      </ProtectedRoute>
      <ProtectedRoute path="/my/personal-shopping" exact>
        <MyPersonalShopping />
      </ProtectedRoute>
      <ProtectedRoute path="/my/news" exact>
        <NewsPage menu={false} />
      </ProtectedRoute>
      <ProtectedRoute path="/my/menus" exact>
        <MenusPage menu={false} />
      </ProtectedRoute>
      <ProtectedRoute path="/my/events" exact>
        <EventsPage menu={false} />
      </ProtectedRoute>
      <ProtectedRoute path="/barcode" exact>
        <BarcodePage />
      </ProtectedRoute>
      <ProtectedRoute path="/mobilepay" exact>
        <MobilepayPage />
      </ProtectedRoute>
      <ProtectedRoute path="/vouchers" exact>
        <VouchersPage />
      </ProtectedRoute>
      <ProtectedRoute path="/shop" exact>
        <ShopPage />
      </ProtectedRoute>
      <ExternalRedirect
        exact={true}
        path={'/external-shop'}
        link={tenantConfig?.tabs.shop.link || '/shop'}
      />
      <ProtectedRoute path="/events" exact>
        <EventsPage />
      </ProtectedRoute>
      <ProtectedRoute path="/pages/:slug" exact>
        <StaticPage />
      </ProtectedRoute>
      <Route path="/login" exact>
        {auth.user ? <Redirect to="/" /> : <LoginPage />}
      </Route>
      <Route path="/activate" exact>
        {auth.user ? <Redirect to="/" /> : <ActivatePage />}
      </Route>
      <Route path="/reset-password" exact>
        <PasswordPage />
      </Route>
      <Route path="/delete-account" exact>
        <DeleteAccountPage />
      </Route>
      <Route path="/" exact>
        <Redirect to={tenantConfig?.routes.root || '/news'} />
      </Route>
    </TabRouter>
  ) : (
    // Mitigate visual flashes by painting parts of eventual page, e.g. Header.
    <Placeholder />
  )
}

export default Router
