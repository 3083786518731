import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(utc)
dayjs.extend(isoWeek)

export const minimumDaysToWithdrawal = 10
export const targetWeekDay = 2

export function mobilepayWithdrawalDate(dateOfTransaction: string): string {
  const date = dayjs.utc(dateOfTransaction).startOf('day')
  const minimumDate = dayjs(date).add(minimumDaysToWithdrawal, 'day')
  const weekdayOfMinimumDate = dayjs(minimumDate).isoWeekday()

  let additionalDays
  if (weekdayOfMinimumDate <= targetWeekDay) {
    additionalDays = targetWeekDay - weekdayOfMinimumDate
  } else {
    additionalDays = 7 - (weekdayOfMinimumDate - targetWeekDay)
  }

  return format(minimumDate.add(additionalDays, 'day'))
}

function format(date: dayjs.Dayjs): string {
  return dayjs(date).format('YYYY-MM-DD')
}
