import { IonContent, IonHeader, IonPage } from '@ionic/react'
import Header from '../components/Header'

// TODO: Is the placeholder still required?
const Placeholder: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <Header logo={false} user={false}></Header>
      </IonHeader>
      <IonContent fullscreen />
    </IonPage>
  )
}

export default Placeholder
