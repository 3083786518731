import * as Sentry from '@sentry/capacitor'
import * as SentrySibling from '@sentry/react'
import { Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { SENTRY_DSN, RELEASE, TENANT } from './config'

const history = createBrowserHistory()

export default function setupSentry() {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  Sentry.init(
    {
      dsn: SENTRY_DSN,
      release: RELEASE,
      initialScope: {
        tags: {
          tenant: TENANT,
        },
      },
      integrations: [
        new SentrySibling.BrowserTracing({
          routingInstrumentation:
            SentrySibling.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 1,
    },
    SentrySibling.init
  )
}

export const SentryRoute = SentrySibling.withSentryRouting(Route)
