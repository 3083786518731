import {
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonButton,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonProgressBar,
  useIonPopover,
  IonBackButton,
} from '@ionic/react'
import { css } from '@emotion/css'
import { personCircleOutline, logOut, key, person } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { TENANT } from '../config'
import { useTenantConfig } from '../lib/providers/tenantConfig'
import { useLoading } from '../lib/providers/loading'
import { useLoginNavigation } from '../lib/hooks/navigation'
import { useUserConfig } from '../lib/providers/userConfig'

const Header: React.FC<{
  back?: boolean
  user?: boolean
  logo?: boolean | 'auto'
  menu?: boolean
  location?: { pathname: string }
}> = ({
  children,
  back = true,
  user = true,
  logo = false,
  menu = false,
  location,
}) => {
  const tenantConfig = useTenantConfig()
  const history = useHistory()
  const [navigateToLogin] = useLoginNavigation()

  const [showProgress] = useProgressIndicator()

  // There is no way to access the location from within this component, because
  // it is not mounted as child component of route. Therefore we rely on the
  // parent component (typically a page) to pass in the location as prop.
  if (logo === 'auto') {
    if (!location) {
      throw new Error("Prop location required with logo: 'auto'")
    }

    // Show logo if current path matches root path
    logo = location?.pathname === tenantConfig?.routes.root
  }

  const onLogout = async () => {
    await navigateToLogin()
    dismiss()
  }

  const onChangePassword = () => {
    history.push('/password')
    dismiss()
  }

  const onMeMyJost = () => {
    history.push('/profile')
    dismiss()
  }

  const [present, dismiss] = useIonPopover(PopoverList, {
    onLogout,
    onChangePassword,
    onMeMyJost,
  })

  return (
    <>
      <IonToolbar>
        {showProgress && (
          <IonProgressBar
            type="indeterminate"
            color="primary"
            className={css`
              position: absolute;
              top: 0;
            `}
          ></IonProgressBar>
        )}
        <IonButtons slot="start">
          {menu && <IonMenuButton></IonMenuButton>}
          {back && <IonBackButton text="Zurück"></IonBackButton>}
        </IonButtons>
        <IonTitle>
          {logo ? (
            <img
              src={`/assets/tenants/${TENANT}/images/logo.png`}
              alt={`${TENANT} logo"`}
              className="header-logo"
            />
          ) : (
            children
          )}
        </IonTitle>
        <IonButtons slot="end">
          {user && (
            <IonButton
              onClick={(e) =>
                present({ event: e.nativeEvent, cssClass: 'user-popover' })
              }
            >
              <IonIcon icon={personCircleOutline}></IonIcon>
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
      <Broadcast />
    </>
  )
}

const PopoverList: React.FC<{
  onLogout: () => void
  onChangePassword: () => void
  onMeMyJost: () => void
}> = ({ onLogout, onChangePassword, onMeMyJost }) => {
  // NOTE: We cannot access the router here. To navigate, we need to pass events
  // to the parent component (which can access the router).
  return (
    <IonList lines="full">
      <IonItem onClick={onLogout}>
        <IonIcon icon={logOut} slot="start"></IonIcon>
        <IonLabel>Abmelden</IonLabel>
      </IonItem>
      <IonItem onClick={onMeMyJost} detail={true}>
        <IonIcon icon={person} slot="start"></IonIcon>
        <IonLabel>Persönliche Daten</IonLabel>
      </IonItem>
      <IonItem onClick={onChangePassword} detail={true} lines="none">
        <IonIcon icon={key} slot="start"></IonIcon>
        <IonLabel>Kennwort ändern</IonLabel>
      </IonItem>
    </IonList>
  )
}

const Broadcast: React.FC = () => {
  const {
    userConfig: {
      broadcast: { enabled, title, description },
    },
  } = useUserConfig()

  return (
    <>
      {enabled && (
        <div className="broadcast">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      )}
    </>
  )
}

function useProgressIndicator({ minWait = 2000 }: { minWait?: number } = {}) {
  const [{ loading }] = useLoading()
  const [showProgress, setShowProgress] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (loading) {
      timeoutId = setTimeout(() => setShowProgress(true), minWait)
    } else {
      setShowProgress(false)
    }

    return function () {
      clearTimeout(timeoutId)
    }
  }, [loading, setShowProgress, minWait])

  return [showProgress, setShowProgress]
}

export default Header
