import { Storage } from '@ionic/storage'
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

// Previous versions of this app used to store credentials in a SQLite database
// https://github.com/ionic-team/ionic-storage#using-localforage-cordovasqlitedriver
const sqliteStorage = new Storage({
  driverOrder: [CordovaSQLiteDriver._driver],
})

export async function setupLegacyStorage(): Promise<void> {
  await sqliteStorage.defineDriver(CordovaSQLiteDriver)
  await sqliteStorage.create()
}

export async function getLegacyCredentials(): Promise<{
  legacyClientId: string | null
  legacyPassword: string | null
}> {
  // See jost-app/src/services/user.service.ts
  return {
    legacyClientId: await sqliteStorage.get('username'),
    legacyPassword: await sqliteStorage.get('password'),
  }
}

export async function isMigrated(): Promise<boolean> {
  return sqliteStorage.get('migrated')
}

export async function flagMigrated(): Promise<void> {
  await sqliteStorage.set('migrated', true)
  await sqliteStorage.set('migratedAt', new Date().toISOString())
}
