import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonButton,
} from '@ionic/react'
import { TENANT } from '../config'
import Header from '../components/Header'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const ShopPage: React.FC = () => {
  const tenantConfig = useTenantConfig()

  return (
    <IonPage>
      <IonHeader>
        <Header menu={true} back={!tenantConfig?.tabs.shop.enabled}>
          {tenantConfig?.tabs.shop.label}
        </Header>
      </IonHeader>
      <IonContent className="shop" fullscreen>
        <img
          src={
            TENANT === 'cj'
              ? 'https://cdn.cjschmidt.de/app/public/img/header-shop.jpg'
              : `/assets/tenants/${TENANT}/images/header-shop.jpg`
          }
          alt="Onlineshop Header"
        />
        <div className="ion-padding-horizontal ion-padding-top">
          <h1>Onlineshop</h1>
          <IonText>Entdecken Sie unsere Modehighlights im Onlineshop.</IonText>
        </div>
        <div className="mt-1 ion-padding-horizontal">
          <IonButton
            color="button-primary"
            href={tenantConfig?.tabs.shop.link}
            target="_blank"
            expand="block"
          >
            Zu unserem Shop
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default ShopPage
