import { RefCallback } from 'react'

// `<IonInput>` has its ref set to the Ionic web component `<ion-input>`. For
// focus to work, we need a ref to the native `<input>` element. We achieve
// this by calling the original ref callback provided by React Hook Form and
// call it with the `<input>` element. We get this element by calling
// `getInputElement()` on the web component. Since this is an async operation,
// we need to await on the result. Fortunately React knows how to handle async
// ref callbacks.
export const delegateDomInput = async (
  formRefCallback: RefCallback<HTMLInputElement>,
  ionElem: HTMLIonInputElement | null
) => {
  // If web component has not been initialized (e.g. when Ionic itself errors out)
  if (!ionElem?.getInputElement) {
    return formRefCallback(null)
  }

  // Retrieve native `<input>` element from web component. See
  // https://ionicframework.com/docs/api/input#methods.
  const inputElem = await ionElem.getInputElement()

  // Invoke the original ref callback with the `<input>` element instead,
  // registering the input element with React Hook Form.
  return formRefCallback(inputElem || null)
}
