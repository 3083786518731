import { loadTenantConfig } from './config'

export default async function setupPlausible() {
  const {
    features: { analytics },
  } = await loadTenantConfig()

  // Disable in development by default. Allow to opt-in with REACT_APP_ANALYTICS=TRUE.
  if (
    process.env['NODE_ENV'] !== 'production' &&
    process.env['REACT_APP_ANALYTICS'] !== 'TRUE'
  ) {
    return
  }

  if (!analytics.enabled) {
    return
  }

  if (/staging/.test(window.location.host)) {
    console.info('[Plausible] Detected staging environment. Disable tracking.')
    return
  }

  // `script.manual.js` does not track page views automatically.
  // https://plausible.io/docs/script-extensions#all-our-script-extensions
  const src = 'https://plausible.io/js/script.manual.local.js'
  appendScript(src, { data: { domain: analytics.siteId } })

  // https://plausible.io/docs/script-extensions#scriptmanualjs
  window.plausible =
    window.plausible ||
    function () {
      ;(window.plausible.q = window.plausible.q || []).push(arguments)
    }
}

export function trackEvent(eventName: string, options?: EventOptions) {
  // Ignore events when Plausible is not setup (or disabled)
  if (!window.plausible) {
    if (process.env.NODE_ENV === 'development') {
      // Debug
      // console.log('[Plausible] Ignoring event', eventName, options)
    }
    return
  }

  // https://github.com/plausible/analytics/blob/fede2f0a8a840601debd246f4a5164d50e723806/tracker/src/plausible.js#L31
  return window.plausible(eventName, options)
}

function appendScript(src: string, { data }: { data: Record<string, string> }) {
  const head = document.head
  const script = document.createElement('script')

  script.src = src
  script.defer = true
  for (let [key, value] of Object.entries(data)) {
    script.dataset[key] = value
  }

  head.appendChild(script)
}

export interface EventOptions {
  props?: { [propName: string]: string | number | boolean | null | undefined }
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    plausible: any
  }
}
