import { useAuth } from '../providers/auth'
import { useHistory } from 'react-router'
import { useUserConfig } from '../providers/userConfig'
import { useAlert } from './alert'

// Navigating to the login page can be a little involved. The user must be
// logged out, and care should be taken to reset the client-side user
// configuration. Sometimes, an alert needs to be displayed or query parameters
// need to be appended to the path to pre-populate fields. This hook
// consolidates all these functionalities in one central location.
export const useLoginNavigation = () => {
  const { clear: clearUserConfig } = useUserConfig()
  const auth = useAuth()
  const history = useHistory()
  const [alert] = useAlert()

  const navigateToLogin = async ({
    message,
    params,
  }: {
    message?: string
    params?: Record<string, string>
  } = {}) => {
    const queryString = new URLSearchParams(params).toString()
    const loginPath = '/login' + (params ? `?${queryString}` : '')

    if (auth.user) {
      await auth.logout()
    }

    clearUserConfig()
    history.replace(loginPath)

    if (message) {
      alert(message)
    }
  }

  return [navigateToLogin]
}
