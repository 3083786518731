import { IonContent, IonHeader, IonIcon, IonPage } from '@ionic/react'
import { MouseEventHandler, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { css } from '@emotion/css'
import { chevronForwardOutline } from 'ionicons/icons'
import { sanitize } from 'dompurify' // TODO: Is there a more light-weight alternative?

import { InnerMessage, RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import WelcomeModal from '../components/WelcomeModal'
import { getNews, News } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'
import { useTenantConfig } from '../lib/providers/tenantConfig'
import { trackUserEvent } from '../lib/analytics'
import storage from '../lib/storage'

const NewsPage: React.FC<{ menu?: boolean }> = ({ menu = true }) => {
  const { data: news, error, fetch } = useRemote<News[]>(getNews)

  const tenantConfig = useTenantConfig()
  const location = useLocation()

  const [welcomeIsRead, setWelcomeIsRead] = useState<boolean>(true)

  useEffect(() => {
    async function asyncEffect() {
      setWelcomeIsRead(await storage.welcomeIsRead())
    }

    asyncEffect()
  })

  return (
    <>
      {menu && <Menu />}
      <IonPage id="main-content">
        <IonHeader>
          <Header
            menu={menu}
            back={!tenantConfig?.tabs.news.enabled}
            logo={'auto'}
            location={location}
          >
            {tenantConfig?.tabs.news.label}
          </Header>
        </IonHeader>
        <IonContent fullscreen>
          {tenantConfig?.features.welcomeModal && (
            <WelcomeModal isOpenInitially={!welcomeIsRead} />
          )}
          <RemoteContent onRetry={fetch} error={error}>
            {news && news.length > 0 ? (
              <>
                {news.map((news) => (
                  <NewsItem news={news} key={news.title} />
                ))}
              </>
            ) : (
              <InnerMessage>
                <div
                  className={css`
                    margin-top: 30vh;
                  `}
                >
                  Aktuell gibt es keine Neuigkeiten.
                </div>
              </InnerMessage>
            )}
          </RemoteContent>
        </IonContent>
      </IonPage>
    </>
  )
}

const NewsItem: React.FC<{ news: News }> = ({ news }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const toggle: MouseEventHandler = (event) => {
    const target: HTMLElement = event.target as HTMLElement

    // Follow link instead of toggling news item
    if (isLink(target)) {
      const href = target.getAttribute('href')

      if (!href) {
        return
      }

      openExternal(href)
      event.preventDefault()
      return
    }

    setIsExpanded(!isExpanded)
  }

  return (
    <div onClick={toggle} className={(isExpanded ? 'expanded ' : ' ') + styles}>
      <div className="description">
        <h2>
          <IonIcon
            icon={chevronForwardOutline}
            className={isExpanded ? 'down' : ''}
          />
          {news.title}
        </h2>
        <div
          className={isExpanded ? '' : 'line-clamp'}
          dangerouslySetInnerHTML={{ __html: sanitize(news.description) }}
        ></div>
      </div>
      <div className="image">
        <img src={news.image} alt={news.title} />
      </div>
    </div>
  )
}

function openExternal(url: string): void {
  // if (typeof cordova !== 'undefined' && cordova.InAppBrowser) {
  //   return cordova.InAppBrowser.open(url, '_system')
  // }
  trackUserEvent('news:external')
  window.open(url, '_blank')
}

function isLink(target: HTMLElement) {
  return target.tagName.toLowerCase() === 'a'
}

const styles = css`
  display: block;
  padding-left: 10px;
  border-bottom: 1px solid var(--ion-color-primary);
  color: var(--ion-color-primary);

  &:last-child {
    border: none;
  }

  &::after {
    content: ' ';
    clear: both;
    display: block;
  }

  .description,
  .image {
    float: left;
    width: 50%;
    cursor: pointer;
  }

  .image {
    img {
      display: block;
    }
  }

  .description {
    overflow: hidden;

    a {
      color: var(--ion-color-secondary);
    }

    > div {
      font-size: 12px;
      padding: 0 5px 0 0;
      margin: 5px 0 5px;
      font-weight: normal;

      /* https://css-tricks.com/line-clampin/#the-standardized-way */
      &.line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (min-height: 667px) {
          -webkit-line-clamp: 7;
        }
      }
    }

    h2 {
      margin: 10px 0 0 0;
      font-size: 15px;
      text-transform: uppercase;

      > ion-icon {
        font-size: 12px;
        transition: transform 300ms ease;

        &.down {
          transform: rotate(90deg);
        }
      }
    }

    b {
      font-weight: bold;
    }

    img {
      display: none;
    }
  }

  &.expanded {
    .description {
      width: 100%;
    }

    .image {
      margin-left: -10px;
      width: calc(100% + 10px);
    }
  }
`

export default NewsPage
