import { IonCheckbox, IonItem, IonLabel } from '@ionic/react'
import { Controller, UseControllerProps } from 'react-hook-form'
import { Errors } from './Errors'

const CheckboxInput = <T,>({
  control,
  rules,
  name,
  label,
}: {
  label: string
  type?: 'text' | 'number' | 'password'
} & UseControllerProps<T>) => {
  return (
    <IonItem>
      <IonLabel>{label}</IonLabel>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({
          field: { onChange, onBlur, value, ref },
          formState: { errors },
        }) => (
          <>
            <IonCheckbox
              onIonChange={(e) => onChange(e.detail.checked)}
              onIonBlur={onBlur}
              checked={value}
            ></IonCheckbox>
            <Errors errors={errors} name={name} />
          </>
        )}
      ></Controller>
    </IonItem>
  )
}

export default CheckboxInput
