import { IonPage, IonHeader, IonContent, IonList } from '@ionic/react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { postResetPassword, errorFromResponse } from '../lib/api'
import { useAuth } from '../lib/providers/auth'
import { useAlert } from '../lib/hooks/alert'
import { useLoading } from '../lib/providers/loading'
import { useLoginNavigation } from '../lib/hooks/navigation'
import Header from '../components/Header'
import { Warning } from '../components/Callout'
import EmailInput from '../components/inputs/EmailInput'
import TextInput from '../components/inputs/TextInput'
import { KeyboardEventHandler, useState } from 'react'
import { descriptiveActivationError, reviewError } from '../lib/errors'
import { ContactSupport, RemoteButton } from '../components/Content'
import PasswordInput from '../components/inputs/PasswordInput'

interface ResetInputs {
  clientId: string
  email: string
  emailConfirmation: string
  password?: string
  passwordConfirmation?: string
}

const PasswordPage: React.FC<{ change?: boolean }> = ({ change }) => {
  const auth = useAuth()
  const [, { start, stop }] = useLoading()
  const [alert] = useAlert()
  const [navigateToLogin] = useLoginNavigation()
  const [error, setError] = useState<{ code: string; message: string } | null>(
    null
  )

  const { control, handleSubmit, getValues } = useForm<ResetInputs>({
    criteriaMode: 'all',
    defaultValues: {
      clientId: auth.user ? auth.user : '',
    },
  })

  const title = change ? 'Ändern' : 'Zurücksetzen'

  const onSubmit: SubmitHandler<ResetInputs> = async (data) => {
    const { clientId } = data
    start()
    const res = await postResetPassword(clientId, normalize(data))

    if (res.ok) {
      stop({ success: true })
      await navigateToLogin({
        message: `Kennwort ${title.toLowerCase()} angefragt. Bitte E-Mail bestätigen.`,
      })
    } else {
      const error = await errorFromResponse(res)
      stop({ success: false })
      console.error(error)
      setError({
        code: error.errno ? `${error.errno}/${error.code}` : error.code,
        message: descriptiveActivationError(error.code, error.status),
      })
    }

    function normalize(data: ResetInputs) {
      const resetData = {
        customer: {
          email: data.email,
        },
      }

      if (data.password) {
        return { ...resetData, password: data.password }
      } else {
        return resetData
      }
    }
  }

  const onError: SubmitErrorHandler<ResetInputs> = (data) => {
    console.error(data)
    alert(reviewError)
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit, onError)()
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <Header user={false}>Kennwort {title.toLowerCase()}</Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        {error && (
          <div className="ion-padding-top">
            <Warning>{error.message}</Warning>
            <p>
              <small>
                <ContactSupport
                  action="das Zurücksetzen"
                  review={true}
                  error={error}
                />
              </small>
            </p>
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          onKeyDown={handleKeyDown}
        >
          <IonList lines="full">
            <TextInput<ResetInputs>
              control={control}
              rules={{ required: 'Kundennummer benötigt' }}
              name="clientId"
              type="number"
              label="Kundennummer"
            ></TextInput>
            <EmailInput<ResetInputs>
              {...{ control, getValues }}
              rules={{ required: 'E-Mail benötigt' }}
              name="email"
              label="E-Mail"
              confirmation={true}
            ></EmailInput>
            {change === true && (
              <PasswordInput<ResetInputs>
                {...{ control, getValues }}
                rules={{
                  required: 'Kennwort benötigt',
                  minLength: {
                    value: 6,
                    message: 'Kennwort zu kurz (mindestens 6 Zeichen)',
                  },
                }}
                name="password"
                label="Kennwort"
                confirmation={true}
              ></PasswordInput>
            )}
            <RemoteButton type="submit" expand="block" className="primary mt-2">
              Kennwort {title.toLowerCase()}
            </RemoteButton>
          </IonList>
        </form>
        <p>
          Zum Schutz Ihrer Daten schicken wir Ihnen zur Bestätigung eine E-Mail.
          Ihr Kennwort bleibt unverändert, bis Sie in der E-Mail auf
          „Bestätigen“ klicken.
        </p>
      </IonContent>
    </IonPage>
  )
}

export default PasswordPage
