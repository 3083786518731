import { ErrorMessage } from '@hookform/error-message'

// NOTE: Requires useForm({criteriaMode: 'all'})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Errors: React.FC<{ errors: any; name: any }> = ({
  errors,
  name,
}) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ messages }) => {
        return messages ? (
          <div className="field-error">
            {Object.entries(messages).map(([type, message]) => (
              <span key={type}>
                <small>{message}</small>
                <br />
              </span>
            ))}
          </div>
        ) : null
      }}
    />
  )
}
