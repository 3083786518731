import { Storage } from '@ionic/storage'
import * as Sentry from '@sentry/capacitor'
import {
  setupLegacyStorage,
  getLegacyCredentials,
  flagMigrated,
  isMigrated,
} from './legacy-storage'

const storage = new Storage()
storage.create()

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async saveCredentials(clientId: string, password: string) {
    await storage.set('clientId', clientId)
    await storage.set('password', password)
  },

  async getCredentials(): Promise<{ clientId: string; password: string }> {
    return {
      clientId: await storage.get('clientId'),
      password: await storage.get('password'),
    }
  },

  async clearCredentials(): Promise<void> {
    await storage.remove('clientId')
    await storage.remove('password')
  },

  async welcomeIsRead(): Promise<boolean> {
    return (await storage.get('welcomeVersion')) === 1
  },

  async markWelcomeAsRead(): Promise<void> {
    await storage.set('welcomeVersion', 1)
    await storage.set('welcomeAt', new Date())
  },

  async setNotificationsAccepted(status: boolean): Promise<void> {
    await storage.set('notificationsAccepted', status)
  },

  async isNotificationsAccepted(): Promise<boolean> {
    return await storage.get('notificationsAccepted')
  },

  async migrateCredentials(): Promise<void> {
    const hasCredentialsInNewStorage = async () => {
      const { clientId, password } = await this.getCredentials()
      return clientId && password
    }

    const migrateCredentialsToNewStorage = async () => {
      const { legacyClientId, legacyPassword } = await getLegacyCredentials()
      if (legacyClientId && legacyPassword) {
        await this.saveCredentials(legacyClientId, legacyPassword)

        // Mark welcome screen as read in current app since it was already shown
        // after initial login in legacy app
        await this.markWelcomeAsRead()

        // Do not remove credentials from legacy store. This is to make sure we
        // have an escape hatch should things fail.
        await flagMigrated()

        console.info(
          `Sucessfully migrated credentials of user ${legacyClientId}`
        )
      }
    }

    // Migrate only if missing credentials
    if (await hasCredentialsInNewStorage()) {
      return
    }

    // Attempt to access legacy database
    try {
      await setupLegacyStorage()
    } catch (err) {
      if (err instanceof Error) {
        console.info('Failed to read legacy credentials:', err.message)
        Sentry.captureException(err)
      }
      return
    }

    // Skip migration if done before. Otherwise migrated users who cleared
    // credentials in new storage (e.g. after logout) would unsolicitedly
    // login with credentials from legacy storage.
    if (await isMigrated()) {
      return
    }

    await migrateCredentialsToNewStorage()
  },
}
