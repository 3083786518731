import { css } from '@emotion/css'
import { IonIcon } from '@ionic/react'
import { alertCircleOutline, closeCircleOutline } from 'ionicons/icons'

export const Callout: React.FC<{ icon?: string; type?: 'info' | 'warning' }> =
  ({ icon = alertCircleOutline, type = 'info', children }) => {
    const typeStyles = {
      info: css`
        background-color: #eaf1f8;
        color: #3a5674;
        border: 1px solid #3a5674;
      `,
      warning: css`
        background-color: #fdeaea;
        color: #bd6e6e;
        border: 1px solid #bd6e6e;
      `,
    }

    return (
      <div
        className={css`
          display: block;
          padding: 10px;
          border-radius: 10px;
          ${typeStyles[type]}

          strong {
            color: inherit;
          }
        `}
      >
        <IonIcon
          icon={icon}
          className={css`
            position: relative;
            top: 2px;
            padding-right: 2px;
          `}
        ></IonIcon>
        {children}
      </div>
    )
  }

export const Warning: React.FC = ({ children }) => {
  return (
    <Callout type="warning" icon={closeCircleOutline}>
      {children}
    </Callout>
  )
}
