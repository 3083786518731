import { ToastOptions, useIonToast } from '@ionic/react'

export const useAlert = () => {
  const [present, dismiss] = useIonToast()
  const alert = (
    message: string,
    options = {
      position: 'top',
      color: 'primary',
      animated: true,
      duration: 3000,
    } as ToastOptions
  ) => {
    present({
      message,
      ...options,
    })
  }

  return [alert, dismiss]
}
