import { IonContent, IonHeader, IonPage } from '@ionic/react'
import { useLocation } from 'react-router-dom'
import { css } from '@emotion/css'
import { sanitize } from 'dompurify' // TODO: Is there a more light-weight alternative?

import { InnerMessage, RemoteContent } from '../components/Content'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { getMenus, Menus } from '../lib/api'
import { useRemote } from '../lib/hooks/remote'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const MenusPage: React.FC<{ menu?: boolean }> = ({ menu = true }) => {
  const { data: menus, error, fetch } = useRemote<Menus[]>(getMenus)

  const tenantConfig = useTenantConfig()
  const location = useLocation()

  return (
    <>
      {menu && <Menu />}
      <IonPage id="main-content">
        <IonHeader>
          <Header
            menu={menu}
            back={!tenantConfig?.tabs.menus.enabled}
            logo={'auto'}
            location={location}
          >
            {tenantConfig?.tabs.menus.label}
          </Header>
        </IonHeader>
        <IonContent fullscreen>
          <RemoteContent onRetry={fetch} error={error}>
            {menus && menus.length > 0 ? (
              <>
                {menus.map((menus) => (
                  <MenusItem menus={menus} key={menus.title} />
                ))}
              </>
            ) : (
              <InnerMessage>
                <div
                  className={css`
                    margin-top: 30vh;
                  `}
                >
                  Aktuell gibt es keine Speisekarte.
                </div>
              </InnerMessage>
            )}
          </RemoteContent>
        </IonContent>
      </IonPage>
    </>
  )
}

const MenusItem: React.FC<{ menus: Menus }> = ({ menus }) => {
  return (
    <div className={styles}>
      <div className="description">
        <h2>{menus.title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(menus.descriptions) }}
        ></div>
      </div>
    </div>
  )
}

const styles = css`
  display: block;
  padding-left: 10px;
  border-bottom: 1px solid var(--ion-color-primary);
  color: var(--ion-color-primary);

  &:last-child {
    border: none;
  }

  &::after {
    content: ' ';
    clear: both;
    display: block;
  }

  .description,
  .image {
    float: left;
    width: 85%;
    cursor: pointer;
  }

  .image {
    img {
      display: block;
    }
  }

  .description {
    overflow: hidden;

    a {
      color: var(--ion-color-secondary);
    }

    > div {
      font-size: 14px;
      padding: 0 5px 0 0;
      margin: 5px 0 5px;

      /* https://css-tricks.com/line-clampin/#the-standardized-way */
      &.line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (min-height: 667px) {
          -webkit-line-clamp: 7;
        }
      }
    }

    h2 {
      margin: 10px 0 0 0;
      font-size: 17px;
      text-transform: uppercase;

      > ion-icon {
        font-size: 12px;
        transition: transform 300ms ease;

        &.down {
          transform: rotate(90deg);
        }
      }
    }

    b {
      font-weight: bold;
    }

    p {
      font-size: 15px;
    }

    img {
      display: none;
    }
  }

  &.expanded {
    .description {
      width: 100%;
    }

    .image {
      margin-left: -10px;
      width: calc(100% + 10px);
    }
  }
`
export default MenusPage
