import { useState, useCallback, useEffect } from 'react'
import { useLoading } from '../providers/loading'

interface LoadingError {
  code: string
  status: number
}

export function useRemote<T>(fetchFn: () => Promise<T>, immediate = true) {
  const [, { start, stop }] = useLoading()
  const [data, setData] = useState<T>()
  const [error, setError] = useState<LoadingError | null>(null)

  const fetch = useCallback(async () => {
    const fail = (error: LoadingError) => {
      stop({ success: false })
      setError(error)
    }

    try {
      start()
      setData(await fetchFn())
      stop({ success: true })
    } catch (err) {
      console.error(err)
      fail(err as LoadingError)
    }
  }, [fetchFn, start, stop])

  useEffect(() => {
    if (immediate) {
      fetch()
    }
  }, [fetch, immediate])

  return { data, error, fetch }
}
