import { Options as JsBarcodeOptions } from 'jsbarcode'

export const TENANT = process.env['REACT_APP_TENANT']!
if (!TENANT) {
  throw new Error('Missing or invalid tenant')
}

export const RELEASE = process.env['REACT_APP_RELEASE']
export const API = process.env['REACT_APP_API'] || '/api'
export const SENTRY_DSN =
  'https://161d7f091a7ac204db7544f74645e4cb@o302428.ingest.sentry.io/4505902338998272'

type ItemConfig = {
  enabled: boolean
  label?: string
}

export interface TenantConfig {
  about: {
    companyName: string
    name: string
    shortName: string
    tagName: string
    phone: string
    phoneLink: string
    mail: string
    website: string
  }
  socialMedia: {
    introText: string
    website: string
    mail: string
    facebook: string
    instagram: string
    youTube: string
    tiktok: string
    pinterest: string
  }
  features: {
    analytics: {
      enabled: boolean
      siteId: string
      userProps: boolean
    }
    welcomeModal: boolean
    barcode: {
      stripLeadingZeros: boolean
      displayValue: boolean
    } & JsBarcodeOptions
  }
  tabs: {
    news: ItemConfig & { icon: string }
    menus: ItemConfig & { icon: string }
    my: ItemConfig & { icon: string }
    barcode: ItemConfig & { icon: string }
    mobilepay: ItemConfig & { icon: string }
    events: ItemConfig & { icon: string }
    vouchers: ItemConfig & { link: string; icon: string }
    shop: ItemConfig & { link: string; externalOnly: boolean; icon: string }
  }
  pages: {
    my: {
      bonuspoints: ItemConfig & {
        header: boolean
        schema: 'loyality' | 'bonus'
      }
      receipts: ItemConfig & { header: boolean }
      trials: ItemConfig & { header: boolean }
      vouchers: ItemConfig & { header: boolean }
      mobilepay: ItemConfig & { header: boolean }
      personalShopping: ItemConfig & {
        appointmentHref: string
        header: boolean
      }
    }
  }
  routes: {
    root: string
  }
}

// TODO: Introduce tenant `muster` and remove respective defaults
export const defaultConfig: TenantConfig = {
  about: {
    companyName: 'Modehaus Mustergültig GmbH & Co.KG',
    name: 'Modehaus Mustergültig',
    shortName: 'Mode Mustergültig',
    tagName: 'Mustergültig',
    phone: '012345/6879-0',
    phoneLink: '+491234568790',
    mail: 'app@muestergueltig.de',
    website: 'https://www.modehaus-mustergültig.de',
  },
  socialMedia: {
    introText: '',
    website: '',
    mail: '',
    facebook: '',
    instagram: '',
    youTube: '',
    tiktok: '',
    pinterest: '',
  },
  features: {
    analytics: {
      enabled: false,
      siteId: '',
      userProps: false,
    },
    barcode: {
      // https://github.com/lindell/JsBarcode/wiki/Options
      format: 'CODE128',
      width: 3,
      displayValue: false,
      // Custom
      stripLeadingZeros: false,
    },
    welcomeModal: false,
  },
  tabs: {
    news: {
      enabled: true,
      label: 'Aktuelles',
      icon: 'newspaperOutline',
    },
    menus: {
      enabled: false,
      label: 'Speisekarte',
      icon: 'restaurantOutline',
    },
    my: {
      enabled: true,
      label: 'myMuster',
      icon: 'heartOutline',
    },
    barcode: {
      enabled: true,
      label: 'Kundenkarte',
      icon: 'barcodeOutline',
    },
    mobilepay: {
      enabled: false,
      icon: 'cardOutline',
    },
    events: {
      enabled: true,
      label: 'Events',
      icon: 'calendarOutline',
    },
    vouchers: {
      enabled: true,
      label: 'Gutscheine',
      link: 'https://gutscheine.modehaus-mustergültig.de',
      icon: 'cartOutline',
    },
    shop: {
      enabled: false,
      label: 'Shop',
      link: 'https://shop.modehaus-mustergültig.de',
      externalOnly: false,
      icon: 'bagHandleOutline',
    },
  },
  pages: {
    my: {
      bonuspoints: {
        enabled: true,
        label: 'Meine Bonuspunkte',
        header: false,
        schema: 'loyality',
      },
      receipts: {
        enabled: true,
        label: 'Meine Einkäufe',
        header: false,
      },
      trials: {
        enabled: true,
        label: 'Offene Auswahlen',
        header: false,
      },
      vouchers: {
        enabled: true,
        label: 'Meine Gutscheine',
        header: false,
      },
      mobilepay: {
        enabled: false,
        label: 'MobilePAY',
        header: false,
      },
      personalShopping: {
        enabled: false,
        label: 'Personal Shopping',
        appointmentHref: '',
        header: false,
      },
    },
  },
  routes: {
    root: '/news',
  },
}

export async function loadTenantConfig() {
  const module: { default: TenantConfig } = await import(
    `./tenants/${TENANT}/config`
  )
  return module.default
}
