import {
  IonButton,
  IonContent,
  IonHeader,
  IonList,
  IonPage,
} from '@ionic/react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { useAlert } from '../../lib/hooks/alert'
import { useAuth } from '../../lib/providers/auth'
import Header from '../../components/Header'
import { Warning } from '../../components/Callout'
import TextInput from '../../components/inputs/TextInput'
import PasswordInput from '../../components/inputs/PasswordInput'
import { ContactSupport, RemoteButton } from '../../components/Content'
import { useEffect, useState, KeyboardEventHandler } from 'react'
import { getLogin } from '../../lib/api'
import { descriptiveLoginError, reviewError } from '../../lib/errors'
import { useLoading } from '../../lib/providers/loading'
import { useTenantConfig } from '../../lib/providers/tenantConfig'

interface LoginInputs {
  clientId: string
  password: string
}

interface LoginError {
  code: string
  message: string
}

const LoginPage: React.FC = () => {
  const location = useLocation<{ from: { pathname: string } }>()
  const history = useHistory()
  const auth = useAuth()
  const [, { start, stop }] = useLoading()
  const [error, setError] = useState<LoginError | null>(null)
  const [alert] = useAlert()

  const { control, handleSubmit, setValue, setFocus } = useForm<LoginInputs>({
    criteriaMode: 'all',
    defaultValues: {
      clientId: '',
      password: '',
    },
  })

  const params = new URLSearchParams(location.search)
  const clientId = params.get('clientId')

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    if (clientId) {
      setValue('clientId', clientId)
      setTimeout(() => setFocus('password'), 200)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [clientId, setValue, setFocus])

  const onSubmit: SubmitHandler<LoginInputs> = async ({
    clientId,
    password,
  }) => {
    start()
    const [err, isAuthenticated] = await authenticate(clientId, password)
    stop({ success: !err })

    if (isAuthenticated) {
      await auth.login(clientId, password)

      // Navigate to previous path (found in state) unless it is /login.
      const fromPath = location.state?.from?.pathname
      const toPath = fromPath === '/login' ? '/' : fromPath
      history.replace(toPath)
    } else {
      if (err) {
        setError({
          ...err,
          message: descriptiveLoginError(err.code),
        })
      }
    }
  }

  const onError: SubmitErrorHandler<LoginInputs> = (data) => {
    console.error(data)
    alert(reviewError)
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit, onError)()
    }
  }

  const tenantConfig = useTenantConfig()

  return (
    <IonPage>
      <IonHeader>
        {/* HACK: Disable the back button to workaround issues where a back
        button would appear after logging out. */}
        <Header back={false} logo={true} user={false}></Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        {error && (
          <div>
            <Warning>{error.message}</Warning>
            <p>
              <small>
                <ContactSupport
                  action="der Login"
                  review={error.code === 'EAUTH'}
                  error={error}
                />
              </small>
            </p>
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          onKeyDown={handleKeyDown}
        >
          <IonList lines="full">
            <TextInput<LoginInputs>
              control={control}
              rules={{ required: 'Kundennummer benötigt' }}
              name="clientId"
              type="number"
              label="Kundennummer"
            ></TextInput>
            <PasswordInput<LoginInputs>
              control={control}
              rules={{ required: 'Kennwort benötigt' }}
              name="password"
              label="Kennwort"
            ></PasswordInput>
            <RemoteButton type="submit" expand="block" className="primary mt-2">
              Login
            </RemoteButton>
            <Link to="/reset-password" className="alternate-action">
              Kennwort vergessen?
            </Link>
          </IonList>
        </form>
        <p className="activate-description">
          Sie besitzen bereits eine Kundenkarte aber sind für unsere{' '}
          {tenantConfig?.about.shortName} App noch nicht freigeschaltet? Sie
          erhalten eine E-Mail mit Ihren individuellen Zugangsdaten.
        </p>
        <IonButton
          routerLink="/activate"
          fill="outline"
          expand="block"
          className="secondary"
        >
          Aktivieren
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

async function authenticate(
  clientId: string,
  password: string
): Promise<[{ code: string; message: string } | null, boolean]> {
  try {
    const res = await getLogin(clientId, password)
    if (res.status === 200) {
      return [null, true]
    } else {
      return [await res.json(), false]
    }
  } catch (_) {
    return [{ code: 'EREMOTE', message: 'Remote error' }, false]
  }
}
export default LoginPage
