import { IonText, IonList, IonItem } from '@ionic/react'
import React, { KeyboardEventHandler } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import pick from 'lodash/pick'
import TextInput from './inputs/TextInput'
import EmailInput from './inputs/EmailInput'
import CheckboxInput from './inputs/CheckboxInput'
import { useTenantConfig } from '../lib/providers/tenantConfig'

export interface CustomerInputs {
  number: string
  firstName?: string
  lastName?: string
  street?: string
  zipCode?: string
  city?: string
  telephone?: string
  mobilePhone?: string
  email?: string
  birthYear?: string
  birthMonth?: string
  birthDay?: string
  advertisement?: boolean
}

const CustomerForm: React.FC<{
  values: CustomerInputs
  onSubmit: SubmitHandler<CustomerInputs>
  onError: SubmitErrorHandler<CustomerInputs>
  header?: React.ReactNode
  hidden?: boolean
  fields?: {
    [Property in keyof CustomerInputs]?: boolean | 'disabled'
  } & {
    birthDate?: boolean | 'disabled'
  }
}> = ({
  values,
  onSubmit,
  onError,
  children,
  header,
  hidden = false,
  fields = {},
}) => {
  const defaultFields = {
    number: true,
    firstName: true,
    lastName: true,
    street: false,
    zipCode: false,
    city: false,
    telephone: false,
    mobilePhone: false,
    email: true,
    birthDate: true,
    birthYear: true,
    birthMonth: true,
    birthDay: true,
    advertisement: true,
  }

  fields = { ...defaultFields, ...fields }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = useForm<CustomerInputs>({
    defaultValues: pick(values, Object.keys(defaultFields)),
    criteriaMode: 'all',
  })

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit, onError)()
    }
  }

  const tenantConfig = useTenantConfig()

  const disabled = !isDirty

  return (
    <form
      hidden={hidden}
      className="ion-padding"
      onSubmit={handleSubmit(onSubmit, onError)}
      onKeyDown={handleKeyDown}
      noValidate
    >
      {header}
      <IonList lines="full">
        {fields['number'] && (
          <TextInput<CustomerInputs>
            control={control}
            rules={{ required: 'Kundennummer benötigt' }}
            name="number"
            type="number"
            label="Kundennummer"
            disabled={fields['number'] === 'disabled'}
          ></TextInput>
        )}
        {fields['firstName'] && (
          <TextInput<CustomerInputs>
            control={control}
            rules={{
              required: 'Vorname benötigt',
            }}
            name="firstName"
            type="text"
            label="Vorname"
            disabled={fields['firstName'] === 'disabled'}
          ></TextInput>
        )}
        {fields['lastName'] && (
          <TextInput<CustomerInputs>
            control={control}
            rules={{ required: 'Nachname benötigt' }}
            name="lastName"
            type="text"
            label="Nachname"
            disabled={fields['lastName'] === 'disabled'}
          ></TextInput>
        )}
        {fields['street'] && (
          <TextInput<CustomerInputs>
            control={control}
            rules={{
              required: 'Straße benötigt',
              pattern: {
                value: /\d/,
                message: 'Hausnummer fehlt',
              },
            }}
            name="street"
            type="text"
            label="Straße"
            disabled={fields['street'] === 'disabled'}
          ></TextInput>
        )}
        {fields['zipCode'] && (
          <TextInput<CustomerInputs>
            control={control}
            rules={{
              required: 'PLZ benötigt',
              pattern: {
                value: /^\d{5}$/,
                message: 'PLZ ungültig',
              },
            }}
            name="zipCode"
            type="number"
            label="PLZ"
            disabled={fields['zipCode'] === 'disabled'}
          ></TextInput>
        )}
        {fields['city'] && (
          <TextInput<CustomerInputs>
            control={control}
            rules={{
              required: 'Ort benötigt',
            }}
            name="city"
            type="text"
            label="Ort"
            disabled={fields['city'] === 'disabled'}
          ></TextInput>
        )}
        {fields['telephone'] && (
          <TextInput<CustomerInputs>
            control={control}
            name="telephone"
            rules={{
              pattern: {
                value: /^\d+$/,
                message: 'Telefonnummer ungültig (enthält Sonderzeichen)',
              },
            }}
            type="tel"
            label="Telefon"
            labelPosition="stacked"
            placeholder="optional"
            disabled={fields['telephone'] === 'disabled'}
          ></TextInput>
        )}
        {fields['mobilePhone'] && (
          <TextInput<CustomerInputs>
            control={control}
            name="mobilePhone"
            rules={{
              pattern: {
                value: /^\d+$/,
                message: 'Telefonnummer ungültig (enthält Sonderzeichen)',
              },
            }}
            type="tel"
            label="Mobil"
            labelPosition="stacked"
            placeholder="optional"
            disabled={fields['mobilePhone'] === 'disabled'}
          ></TextInput>
        )}
        {fields['email'] && (
          <EmailInput<CustomerInputs>
            control={control}
            rules={{ required: 'E-Mail benötigt' }}
            name="email"
            label="E-Mail"
            confirmation={true}
            getValues={getValues}
            disabled={fields['email'] === 'disabled'}
          ></EmailInput>
        )}
        {fields['birthDate'] && (
          <>
            <IonItem lines="none">Geburtsdatum</IonItem>
            <TextInput<CustomerInputs>
              control={control}
              rules={{
                required: 'Tag benötigt',
                min: {
                  value: 1,
                  message: 'Tag ungültig',
                },
                max: {
                  value: 31,
                  message: 'Tag ungültig',
                },
              }}
              name="birthDay"
              type="number"
              label="Tag"
              labelPosition="fixed"
              placeholder="TT"
              disabled={fields['birthDate'] === 'disabled'}
            ></TextInput>
            <TextInput<CustomerInputs>
              control={control}
              rules={{
                required: 'Monat benötigt',
                min: {
                  value: 1,
                  message: 'Monat ungültig',
                },
                max: {
                  value: 12,
                  message: 'Monat ungültig',
                },
              }}
              name="birthMonth"
              type="number"
              label="Monat"
              labelPosition="fixed"
              placeholder="MM"
              disabled={fields['birthDate'] === 'disabled'}
            ></TextInput>
            <TextInput<CustomerInputs>
              control={control}
              rules={{
                required: 'Jahr benötigt',
                min: {
                  value: 1900,
                  message: 'Jahr ungültig',
                },
                max: {
                  value: 2100,
                  message: 'Monat ungültig',
                },
              }}
              name="birthYear"
              type="number"
              label="Jahr"
              labelPosition="fixed"
              placeholder="JJJJ"
              disabled={fields['birthDate'] === 'disabled'}
            ></TextInput>
          </>
        )}
        {fields['advertisement'] && (
          <>
            <CheckboxInput<CustomerInputs>
              control={control}
              name="advertisement"
              label="Newsletter"
            ></CheckboxInput>
            <div className="ion-padding-horizontal ion-padding-top">
              <IonText color="medium">
                <small>
                  Ich gestatte der {tenantConfig?.about.companyName} mich per
                  Post und per E-Mail über Neuigkeiten zu informieren. Meine
                  Daten werden nicht an Dritte weitergegeben.
                </small>
              </IonText>
            </div>
          </>
        )}
        {/* HACK: Add the disabled prop to every child node (element). This is
        a bit of a hack, but it is conventional enough that ChatGPT knows about
        it. See https://react.dev/reference/react/Children#transforming-children */}
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { disabled })
          }
          return child
        })}
      </IonList>
    </form>
  )
}

export default CustomerForm
