import { IonPage, IonHeader, IonContent } from '@ionic/react'
import Header from '../components/Header'
import { useTenantConfig } from '../lib/providers/tenantConfig'
import { RemoteButton } from '../components/Content'
import { Link } from 'react-router-dom'
import { delActivate } from '../lib/api'
import { useAuth } from '../lib/providers/auth'
import { useLoading } from '../lib/providers/loading'
import { useLoginNavigation } from '../lib/hooks/navigation'

const DeleteAccountPage: React.FC = () => {
  const tenantConfig = useTenantConfig()
  const auth = useAuth()
  const [, { start, stop }] = useLoading()
  const [navigateToLogin] = useLoginNavigation()

  const onClick = async () => {
    start()
    await delActivate(auth.user!)
    stop({ success: true })
    navigateToLogin({ message: 'Benutzerkonto gelöscht.' })
  }

  return (
    <IonPage>
      <IonHeader>
        <Header user={false}>Benutzerkonto löschen</Header>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <p>
          Sie können hier das für diese App erforderliche persönliche
          Benutzerkonto löschen. Bitte beachten Sie, dass Sie sich nach
          erfolgter Löschung nicht mehr in der App anmelden können.
        </p>
        <p>
          Ihre Kundenkarte können Sie weiterhin auch ohne App verwenden.
          Eventuell erhaltene Vorteile (Bonus- oder Treuepunkte, Gutscheine)
          sind von der Löschung Ihres Benutzerkontos nicht betroffen. Um auch
          ihre persönlichen Daten in Verbindung mit Ihrer Kundenkarte zu
          löschen, wenden Sie sich bitte an Tel.&nbsp;
          <a href={'tel:' + tenantConfig?.about.phoneLink}>
            {tenantConfig?.about.phone}
          </a>
          .
        </p>
        <RemoteButton
          type="submit"
          onClick={onClick}
          expand="block"
          className="danger mt-2"
        >
          Benutzerkonto unwiderruflich löschen
        </RemoteButton>
        <Link to="/" className="alternate-action">
          Abbrechen
        </Link>
      </IonContent>
    </IonPage>
  )
}

export default DeleteAccountPage
