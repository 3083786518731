import { useState } from 'react'
import { IonIcon } from '@ionic/react'
import { css } from '@emotion/css'
import { UseControllerProps, UseFormGetValues } from 'react-hook-form'
import { eye, eyeOff } from 'ionicons/icons'
import TextInput from './TextInput'

interface PasswordInputPropsBase {
  label: string
  toggle?: boolean
}

interface PasswordInputProps extends PasswordInputPropsBase {
  confirmation?: false
  getValues?: never
}

interface PasswordInputPropsConfirmation<T> extends PasswordInputPropsBase {
  getValues: UseFormGetValues<T>
  confirmation: true
}

const PasswordInput = <T,>({
  control,
  rules,
  name,
  label,
  toggle = true,
  confirmation = false,
  getValues,
}: (PasswordInputProps | PasswordInputPropsConfirmation<T>) &
  UseControllerProps<T>) => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <>
      <Password
        {...{ control, rules, name, label, toggle }}
        passwordVisible={passwordVisible}
        onTogglePassword={togglePassword}
      />
      {confirmation && (
        <Password
          control={control}
          name={`${name}Confirmation` as typeof name}
          rules={{
            required: `Bestätigung ${label} benötigt`,
            validate: {
              match: (value) => {
                if (typeof getValues === 'undefined') {
                  throw new Error('Missing prop getValues')
                }

                if (value !== getValues(name)) {
                  return `${label} stimmt nicht überein`
                }
                return true
              },
            },
          }}
          label={`${label} bestätigen`}
          toggle={false}
          passwordVisible={passwordVisible}
          onTogglePassword={togglePassword}
        />
      )}
    </>
  )
}

const Password = <T,>({
  control,
  rules,
  name,
  label,
  toggle,
  passwordVisible,
  onTogglePassword,
}: {
  label: string
  toggle: boolean
  passwordVisible: boolean
  onTogglePassword: () => void
} & UseControllerProps<T>) => {
  const passwordIcon = passwordVisible ? eye : eyeOff

  return (
    <TextInput
      {...{ control, rules, name, label }}
      type={passwordVisible ? 'text' : 'password'}
    >
      {toggle && (
        <IonIcon
          slot="end"
          icon={passwordIcon}
          className={css`
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 10;
          `}
          onClick={onTogglePassword}
        ></IonIcon>
      )}
    </TextInput>
  )
}

export default PasswordInput
