import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
} from '@ionic/react'
import { Link, useLocation } from 'react-router-dom'
import { css } from '@emotion/css'

import { TENANT } from '../config'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { VoucherBadge } from '../components/VoucherBadge'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const MyPage: React.FC = () => {
  const tenantConfig = useTenantConfig()
  const location = useLocation()

  return (
    <>
      <Menu />
      <IonPage id="main-content">
        <IonHeader>
          <Header
            menu={true}
            back={!tenantConfig?.tabs.my.enabled}
            logo={'auto'}
            location={location}
          >
            {tenantConfig?.tabs.my.label}
          </Header>
        </IonHeader>
        <IonContent fullscreen>
          <Content />
        </IonContent>
      </IonPage>
    </>
  )
}

const ContentDefault: React.FC = () => {
  const tenantConfig = useTenantConfig()

  return (
    <>
      <img
        src={`/assets/tenants/${TENANT}/images/header-my.jpg`}
        alt={tenantConfig?.tabs.my.label}
      />
      <List />
    </>
  )
}

const ContentCj: React.FC = () => {
  const tenantConfig = useTenantConfig()

  return (
    <>
      <img
        src={'https://cdn.cjschmidt.de/app/public/img/header-my.jpg'}
        alt={tenantConfig?.tabs.my.label}
      />
      <Tiles />
    </>
  )
}

let Content: typeof ContentDefault
if (TENANT === 'cj') {
  Content = ContentCj
} else {
  Content = ContentDefault
}

const List: React.FC = () => {
  const tenantConfig = useTenantConfig()
  const my = tenantConfig?.pages.my

  return (
    <IonList lines="full">
      {my?.bonuspoints.enabled && (
        <IonItem routerLink="/my/bonuspoints">
          <IonLabel>{my.bonuspoints.label}</IonLabel>
        </IonItem>
      )}
      {my?.receipts.enabled && (
        <IonItem routerLink="/my/receipts">
          <IonLabel>{my.receipts.label}</IonLabel>
        </IonItem>
      )}
      {my?.trials.enabled && (
        <IonItem routerLink="/my/trials">
          <IonLabel>{my.trials.label}</IonLabel>
        </IonItem>
      )}
      {my?.vouchers.enabled && (
        <IonItem routerLink="/my/vouchers">
          <IonLabel>{my.vouchers.label}</IonLabel>
          <VoucherBadge />
        </IonItem>
      )}
      {my?.mobilepay.enabled && (
        <IonItem routerLink="/my/mobilepay">
          <IonLabel>{my.mobilepay.label}</IonLabel>
        </IonItem>
      )}
      {my?.personalShopping.enabled && (
        <IonItem routerLink="/my/personal-shopping">
          <IonLabel>{my.personalShopping.label}</IonLabel>
        </IonItem>
      )}
    </IonList>
  )
}

const Tiles: React.FC = () => {
  const tenantConfig = useTenantConfig()
  const my = tenantConfig?.pages.my

  return (
    <div
      className={css`
        --tile-background-color-alt1: #88c8cb;
        --tile-background-color-alt2: #a0ced0;
        --tile-background-color-alt3: #b6e0e2;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: -6px 16px 16px;

        .tile {
          width: calc(50% - 8px);
          aspect-ratio: 1 / 1;
          margin-top: 16px;
          text-decoration: none;
          color: inherit;
        }

        .tile:nth-child(3n + 1) {
          background-color: var(--tile-background-color-alt1);
        }

        .tile:nth-child(3n + 2) {
          background-color: var(--tile-background-color-alt2);
        }

        .tile:nth-child(3n) {
          background-color: var(--tile-background-color-alt3);
        }

        .tile-icon {
          display: flex;
          height: 50%;

          > img {
            margin: auto;
            display: block;
            height: 50%;
          }
        }

        .tile-icon--vouchers {
          position: relative;

          ion-badge {
            position: absolute;
            top: 15px;
            right: 48px;
          }
        }

        .tile-label {
          position: relative;
          height: 50%;
          margin: 0 5%;
          padding: 10%;
          text-align: center;
        }
        .tile-label:after {
          content: '';
          background: #00000029;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 1px;
          width: 80%;
        }
      `}
    >
      {my?.receipts.enabled && (
        <Link className="tile" to="/my/receipts">
          <div className="tile-icon">
            <img
              src={`/assets/tenants/${TENANT}/images/my/tiles/receipts.svg`}
              alt=""
            />
          </div>
          <div className="tile-label">{my.receipts.label}</div>
        </Link>
      )}
      {my?.bonuspoints.enabled && (
        <Link className="tile" to="/my/bonuspoints">
          <div className="tile-icon">
            <img
              src={`/assets/tenants/${TENANT}/images/my/tiles/bonuspoints.svg`}
              alt=""
            />
          </div>
          <div className="tile-label">{my.bonuspoints.label}</div>
        </Link>
      )}
      {my?.vouchers.enabled && (
        <Link className="tile" to="/my/vouchers">
          <div className="tile-icon tile-icon--vouchers">
            <img
              src={`/assets/tenants/${TENANT}/images/my/tiles/vouchers.svg`}
              alt=""
            />
            <VoucherBadge />
          </div>
          <div className="tile-label">{my.vouchers.label}</div>
        </Link>
      )}
      {my?.personalShopping.enabled && (
        <Link className="tile" to="/my/personal-shopping">
          <div className="tile-icon">
            <img
              src={`/assets/tenants/${TENANT}/images/my/tiles/personal-shopping.svg`}
              alt=""
            />
          </div>
          <div className="tile-label tile-label--3-lines">
            {my.personalShopping.label}
          </div>
        </Link>
      )}
      {TENANT === 'cj' && (
        <>
          {/* News exist in default, but are not accessible from my page */}
          <Link className="tile" to="/my/menus">
            <div className="tile-icon">
              {/* News in default, used as Menu in CJ */}
              <img
                src={`/assets/tenants/${TENANT}/images/my/tiles/menu.svg`}
                alt=""
              />
            </div>
            <div className="tile-label">Utsicht</div>
          </Link>
          {/* Events exist in default, but are not accessible from my page */}
          <Link className="tile" to="/my/events">
            <div className="tile-icon">
              <img
                src={`/assets/tenants/${TENANT}/images/my/tiles/events.svg`}
                alt=""
              />
            </div>
            <div className="tile-label">Termine</div>
          </Link>
        </>
      )}
    </div>
  )
}

export default MyPage
