import { UseControllerProps, UseFormGetValues } from 'react-hook-form'
import TextInput from './TextInput'

const EMAIL_REGEXP = /^.+@.+\..+$/

interface EmailInputPropsBase {
  label: string
  confirmation?: boolean
  disabled?: boolean
}

interface EmailInputProps extends EmailInputPropsBase {
  confirmation?: false
  getValues?: never
}

interface EmailInputPropsConfirmation<T> extends EmailInputPropsBase {
  getValues: UseFormGetValues<T>
  confirmation: true
}

const EmailInput = <T,>({
  control,
  rules,
  name,
  label,
  confirmation = false,
  disabled = false,
  getValues,
}: (EmailInputProps | EmailInputPropsConfirmation<T>) &
  UseControllerProps<T>) => {
  return (
    <>
      <Email {...{ control, rules, name, label, disabled }}></Email>
      {confirmation && !disabled && (
        <Email
          control={control}
          name={`${name}Confirmation` as typeof name}
          rules={{
            required: `Bestätigung ${label} benötigt`,
            validate: {
              match: (value) => {
                if (typeof getValues === 'undefined') {
                  throw new Error('Missing prop getValues')
                }

                if (value !== getValues(name)) {
                  return `${label} stimmt nicht überein`
                }
                return true
              },
            },
          }}
          label={`${label} bestätigen`}
        />
      )}
    </>
  )
}

const Email = <T,>({
  control,
  rules,
  name,
  label,
  disabled,
}: {
  label: string
  disabled?: boolean
} & UseControllerProps<T>) => {
  rules = {
    ...rules,
    pattern: { value: EMAIL_REGEXP, message: `${label} ungültig` },
  }
  return (
    <TextInput
      {...{ control, rules, name, label, disabled, type: 'email' }}
    ></TextInput>
  )
}

export default EmailInput
