import { IonPage, IonHeader, IonContent } from '@ionic/react'
import { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { Link } from 'react-router-dom'

import { TENANT } from '../../config'
import { useUserConfig } from '../../lib/providers/userConfig'
import { useTenantConfig } from '../../lib/providers/tenantConfig'
import { getMe } from '../../lib/api'
import Header from '../../components/Header'
import { RemoteContent } from '../../components/Content'
import { useRemote } from '../../lib/hooks/remote'
import { toEuro } from '../../lib/utils'

const MyBonuspointsPage: React.FC = () => {
  const tenantConfig = useTenantConfig()

  return (
    <IonPage>
      <IonHeader>
        <Header>{tenantConfig?.pages.my.bonuspoints.label}</Header>
      </IonHeader>
      <IonContent fullscreen className="my my-bonuspoints">
        {tenantConfig?.pages.my.bonuspoints.header && (
          <img
            src={
              TENANT === 'cj'
                ? 'https://cdn.cjschmidt.de/app/public/img/header-my-bonuspoints.jpg'
                : `/assets/tenants/${TENANT}/images/header-my-bonuspoints.jpg`
            }
            alt=""
          />
        )}
        <div className="ion-padding-horizontal ion-padding-top">
          {TENANT === 'cj' && (
            <h1>{tenantConfig?.pages.my.bonuspoints.label}</h1>
          )}
          {tenantConfig?.pages.my.bonuspoints.schema === 'loyality' && (
            <ContentLoyality />
          )}
          {tenantConfig?.pages.my.bonuspoints.schema === 'bonus' && (
            <ContentBonus />
          )}
        </div>
      </IonContent>
    </IonPage>
  )
}

// TODO: Support tenants other than Jost
const ContentLoyality: React.FC = () => {
  const [totalPoints, setTotalPoints] = useState<number>(0)
  const [points, setPoints] = useState<number>(0)
  const [cents, setCents] = useState<number>(0)

  const { userConfig } = useUserConfig()

  const {
    data: customer,
    error,
    fetch,
  } = useRemote<{ loyalityPoints: number }>(getMe)

  useEffect(() => {
    if (customer) {
      setTotalPoints(customer['loyalityPoints'])
    }
  }, [customer])

  useCountTo(totalPoints, setPoints)
  useCountTo(availableCents(totalPoints), setCents)

  return (
    <RemoteContent onRetry={fetch} error={error}>
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;

          h2 {
            width: 50%;
            margin-bottom: 5px;
            font-variant-numeric: proportional-nums;
            font-feature-settings: 'pnum';
            font-size: 4rem;
            text-align: right;
            white-space: nowrap;
          }

          .caption {
            align-self: center;
            padding-left: 0.5rem;
            color: var(--ion-color-primary);
            font-size: 2rem;
            font-weight: 200;
            text-transform: uppercase;
          }
        `}
      >
        <h2>{points}</h2>
        <div className="caption">Punkte</div>
      </div>
      <div>
        <p>
          Ihre Bonuspunkte entsprechen:&nbsp;
          <strong>{toEuro(cents / 100)}</strong>.
        </p>
        {userConfig.vouchers.upcoming && (
          <p
            className={css`
              background-color: var(--ion-color-light);
              padding: 20px;
            `}
          >
            Bonuspunkte vermisst? Ihre Bonuspunkte wurden kürzlich in einen
            neuen Gutschein umgewandelt. Diesen finden Sie innerhalb der
            nächsten Wochen unter&nbsp;
            <Link to="/my/vouchers">Meine Gutscheine</Link>. Sie erhalten eine
            Push-Benachrichtigung, sobald der Gutschein zur Verfügung steht.
          </p>
        )}
        <p>
          Beim Einkauf mit der Jost Kundenkarte erhalten Sie 5 Bonuspunkte für
          jeden Euro Umsatz – auch auf reduzierte Ware. Wir schicken Ihnen
          regelmäßig einen Bonusscheck über die angesparte Summe zu. 500 Punkte
          enstprechen dabei einem Gegenwert von einem Euro. So wird das
          Einkaufen bei Jost noch günstiger für Sie!
        </p>
        <p>
          Ihre Bonuspunkte werden zwei mal im Jahr automatisch in einen
          Gutschein umgewandelt. Den Gutschein finden Sie in dieser App
          unter&nbsp;
          <Link to="/my/vouchers">Meine Gutscheine</Link>.
        </p>
      </div>
    </RemoteContent>
  )
}

function availableCents(availablePoints: number): number {
  const pointsToCentRatio = 0.2
  if (!availablePoints) return 0
  return availablePoints * pointsToCentRatio
}

function useCountTo(total: number, setter: (value: number) => void) {
  function incrementer(total: number, duration = 3000) {
    const fps = 60
    const frames = duration / fps
    const increment = Math.floor(total / frames)

    let current = 0
    return () => {
      current = Math.min(total, current + increment)
      return { current, total }
    }
  }

  const animate = useCallback(
    (
      incrementer: () => { current: number; total: number },
      setter: (value: number) => void
    ) => {
      return requestAnimationFrame(() => {
        const { current, total } = incrementer()
        setter(current)

        if (current === total) {
          return
        }

        animate(incrementer, setter)
      })
    },
    []
  )

  return useEffect(() => {
    const animationFrameId = animate(incrementer(total), setter)
    return () => {
      cancelAnimationFrame(animationFrameId)
    }
  }, [animate, setter, total])
}

// TODO: Support tenants other than CJ
const ContentBonus: React.FC = () => {
  const { data: customer, error, fetch } = useRemote(getMe)
  const [bonus, setBonus] = useState<Bonus | null>(null)

  useEffect(() => {
    if (customer) {
      setBonus(
        new Bonus({
          value: customer.bonusValue,
          revenue: customer.revenue,
        })
      )
    }
  }, [customer])

  function getBonusText(currentBonus: Bonus): string {
    let bonusText: string = ''
    if (
      currentBonus.current.percent !==
      Bonus.levels[Bonus.levels.length - 1].percent
    ) {
      bonusText =
        ' Die nächste Bonusstaffel ' +
        currentBonus.next.percent +
        '% erreichen Sie bei weiteren Einkäufen im Wert von ' +
        toEuro(currentBonus.next.revenueFrom - currentBonus.revenue) +
        '.'
    }
    return bonusText
  }

  return (
    <RemoteContent onRetry={fetch} error={error}>
      {bonus && (
        <div
          className={css`
            p,
            ul {
              font-size: 0.9rem;
            }
          `}
        >
          <h2
            className={css`
              text-align: center;
              font-size: 2.5rem;
              line-height: 2;
            `}
          >
            {toEuro(bonus.value)}
          </h2>
          <p>
            Mit Ihrer CJ&nbsp;Schmidt Vorteilskarte erhalten Sie bis zu 4% Bonus
            auf Ihre Einkäufe gutgeschrieben. Zweimal im Jahr erhalten Sie Ihren
            Bonusscheck, den Sie in allen Häusern von CJ&nbsp;Schmidt einlösen
            können.
          </p>
          <p
            className={css`
              font-weight: 400;
            `}
          >
            Sie erhalten aktuell {bonus.current.percent}% Bonus auf Ihre
            regulären Einkäufe.
            {getBonusText(bonus)}
          </p>
          <ul
            className={css`
              padding: 0 20px;
            `}
          >
            <li>
              4% Bonus ab {toEuro(1000, { maximumFractionDigits: 0 })} Umsatz
            </li>
            <li>
              3% Bonus ab {toEuro(500, { maximumFractionDigits: 0 })} Umsatz
            </li>
            <li>2% Bonus ab Ihrem ersten Einkauf bei CJ&nbsp;Schmidt</li>
          </ul>
        </div>
      )}
    </RemoteContent>
  )
}

export class Bonus {
  static levels = [
    { revenueFrom: 0, revenueTo: 499, percent: 2 },
    { revenueFrom: 500, revenueTo: 999, percent: 3 },
    { revenueFrom: 1000, revenueTo: Infinity, percent: 4 },
  ]

  public value: number
  public revenue: number

  constructor({ value, revenue }: { value: number; revenue: number }) {
    this.value = value
    this.revenue = revenue
  }

  get current() {
    return Bonus.levels[this.computeLevelByRevenue()]
  }

  get next() {
    const maxLevels = Bonus.levels.length - 1
    const nextLevel = Math.min(maxLevels, this.computeLevelByRevenue() + 1)
    return Bonus.levels[nextLevel]
  }

  computeLevelByRevenue(revenue = this.revenue) {
    let index = 0
    for (let level of Bonus.levels) {
      if (revenue < level.revenueTo) {
        return index
      }
      index += 1
    }
    return Bonus.levels.length - 1
  }
}

export default MyBonuspointsPage
