import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonButton,
} from '@ionic/react'

import { TENANT } from '../../config'
import Header from '../../components/Header'
import { useTenantConfig } from '../../lib/providers/tenantConfig'

const MyPersonalShopping: React.FC = () => {
  const tenantConfig = useTenantConfig()

  return (
    <IonPage>
      <IonHeader>
        <Header>{tenantConfig?.pages.my.personalShopping.label}</Header>
      </IonHeader>
      <IonContent className="my my-personal-shopping" fullscreen>
        {tenantConfig?.pages.my.personalShopping.header && (
          <img
            src={
              TENANT === 'cj'
                ? 'https://cdn.cjschmidt.de/app/public/img/header-my-personal-shopping.jpg'
                : `/assets/tenants/${TENANT}/images/header-my-personal-shopping.jpg`
            }
            alt=""
          />
        )}
        <div className="ion-padding-horizontal ion-padding-top">
          <h1>{tenantConfig?.pages.my.personalShopping.label}</h1>
          <IonText className="text">
            Ihre persönliche Styling-Beratung ist nur für Sie da. Gerne treffen
            wir vorab eine speziell auf Ihre Bedürfnisse zugeschnittene
            Vorauswahl und entdecken gemeinsam, was am besten zu Ihnen passt und
            Ihren Stil optimal unterstreicht.
          </IonText>
        </div>
        <div className="mt-1 ion-padding-horizontal">
          {/* TODO: Link */}
          <IonButton
            color="button-primary"
            href={tenantConfig?.pages.my.personalShopping.appointmentHref}
            target="_blank"
            expand="block"
          >
            Termin vereinbaren
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default MyPersonalShopping
