import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getPlatforms } from '@ionic/react'
import { useAuth } from '../providers/auth'
import { trackAppEvent, trackUserEvent } from '../analytics'
import { useOnResume } from './resume'

export function useTrackPageview() {
  const location = useLocation()
  const loggedIn = !!useAuth().user
  const track = loggedIn ? trackUserEvent : trackAppEvent

  useEffect(() => {
    track('pageview')
  }, [location, track])

  useOnResume(() => {
    // Track current page of resumed application as pageview
    if (getPlatforms().includes('capacitor')) {
      track('pageview')
    }
  })
}
