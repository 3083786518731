// https://github.com/puregarlic/react-use-loading/blob/master/src/index.ts
import {
  useState,
  useCallback,
  useContext,
  createContext,
  ReactNode,
} from 'react'

export type LoadingHookValues = {
  loading: boolean
  error: boolean
  success: boolean
}

export type LoadingHookMethods = {
  start: () => void
  stop: ({ success }: { success: boolean }) => void
}

const loadingIndicatorContext = createContext<
  [LoadingHookValues, LoadingHookMethods]
>([{} as LoadingHookValues, {} as LoadingHookMethods])

export function useLoading() {
  return useContext(loadingIndicatorContext)
}

function useProvideLoading(): [LoadingHookValues, LoadingHookMethods] {
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(true)

  const start = useCallback(() => {
    setLoading(true)
  }, [])

  const stop = useCallback(({ success = true } = {}) => {
    setLoading(false)
    setSuccess(success)
  }, [])

  return [
    {
      loading,
      success,
      error: !loading && !success,
    },
    { start, stop },
  ]
}

export function ProvideLoading({ children }: { children: ReactNode }) {
  const loading = useProvideLoading()

  return (
    <loadingIndicatorContext.Provider value={loading}>
      {children}
    </loadingIndicatorContext.Provider>
  )
}
