import { useEffect, useRef, useState } from 'react'
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react'
import { getPagesCached, Page } from '../lib/api'
import { useHistory } from 'react-router-dom'
import { SocialMedia } from './SocialMedia'

const Menu: React.FC<{}> = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menu = useRef<any>(null)
  const history = useHistory()
  const [pages, setPages] = useState<Page[]>([])

  const setMenuPages = (pages: Page[]) => {
    const menuPages = pages.filter((p) => p.menu)
    setPages(menuPages)
  }

  // Load (prefetched) pages from cache
  useEffect(() => {
    const effect = async () => {
      setMenuPages(await getPagesCached())
    }
    effect()
  }, [])

  // Always update (indefinitely) cached pages when opening the menu.
  const onMenuOpen = async () => {
    getPagesPristine()
  }

  const getPagesPristine = async () => {
    getPagesCached.clear()
    setMenuPages(await getPagesCached())
  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      menu.current?.close()
    })

    return unlisten
  })

  return (
    <IonMenu
      side="start"
      content-id="main-content"
      onIonDidOpen={onMenuOpen}
      ref={menu}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Mehr</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {pages.map((page: Page) => (
            <IonItem key={page.title} routerLink={`/pages/${page.slug}`}>
              <IonLabel>{page.title}</IonLabel>
            </IonItem>
          ))}
        </IonList>
        <SocialMedia></SocialMedia>
      </IonContent>
    </IonMenu>
  )
}

export default Menu
