import dayjs from 'dayjs'
import { trackEvent, EventOptions } from '../plausible'
import { RELEASE, TENANT, loadTenantConfig } from '../config'
import { getMeCached } from './api'

// App events have release and tenant props
export async function trackAppEvent(
  eventName: string,
  options: EventOptions = { props: {} }
) {
  const defaults = { release: RELEASE, tenant: TENANT }
  options.props = { ...options?.props, ...defaults }
  trackEvent(eventName, options)
}

// Like app events, but with additional non-identifiable user props (e.g.
// gender, zipCode) if feature `analytics.userProps` is enabled for tenant.
export async function trackUserEvent(
  eventName: string,
  options: EventOptions = { props: {} }
) {
  const {
    features: { analytics },
  } = await loadTenantConfig()
  options.props = analytics.userProps
    ? { ...options?.props, ...(await properties()) }
    : options?.props
  return trackAppEvent(eventName, options)
}

async function properties() {
  const {
    dateOfBirth,
    gender,
    zipCode,
    branchNumber,
    subscriptionNewsletter,
    isStaff,
  } = await getMeCached()
  return {
    loggedIn: true,
    ageGroup: ageGroup(dateOfBirth),
    gender: genderAbbreviation(gender),
    zipCode: abbreviateZipCode(zipCode),
    branchNumber,
    subscriptionNewsletter,
    isStaff,
  }
}

function ageGroup(dateOfBirth: string) {
  const now = dayjs()
  const yearOfBirth = dayjs(dateOfBirth).year()
  const obfuscatedDateOfBirth = dayjs(`${yearOfBirth}-01-01`)
  const age = now.diff(obfuscatedDateOfBirth, 'year')
  return Math.floor(age / 5) * 5
}

function abbreviateZipCode(zipCode: string): string {
  return zipCode.substring(0, zipCode.length - 1) + 'x'
}

function genderAbbreviation(gender: number) {
  return {
    0: undefined,
    1: 'f',
    2: 'm',
    3: 'g', // group (Familie)
    4: 'x', // diverse
  }[gender]
}
