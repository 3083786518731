import {
  useIonModal,
  IonIcon,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
} from '@ionic/react'
import { Fragment, useEffect } from 'react'
import { chevronForwardOutline } from 'ionicons/icons'
import { css } from '@emotion/css'
import { API } from '../config'
import { Receipt, ReceiptPosition } from '../lib/api'
import { InnerMessage } from './Content'
import { toDate, toEuro, toPercent } from '../lib/utils'
import { mobilepayWithdrawalDate } from '../lib/mobilepay'
import { trackUserEvent } from '../lib/analytics'
import Barcode from './Barcode'

export const ReceiptList: React.FC<{
  receipts: Receipt[]
  sum?: boolean
  withdrawalDate?: boolean
  itemLink: (receipt: Receipt) => string
  itemSum?: (receipt: Receipt) => number
  notFoundMessage?: string
}> = ({
  receipts,
  itemLink,
  itemSum = (receipt) => receipt.totalPrice,
  sum = false,
  withdrawalDate = false,
  notFoundMessage = 'Für den ausgewählten Zeitraum liegen keine Belege vor.',
}) => {
  const sumPrice = (receipts: Receipt[]) => {
    return receipts.reduce((sum, receipt: Receipt) => {
      return sum + itemSum(receipt)
    }, 0)
  }

  return (
    <>
      {receipts.length > 0 ? (
        <IonList lines="full">
          {receipts.map((receipt) => (
            <IonItem routerLink={itemLink(receipt)} key={receipt.receiptNumber}>
              <div slot="start">
                {toDate(
                  withdrawalDate
                    ? mobilepayWithdrawalDate(receipt.date)
                    : receipt.date
                )}
              </div>
              <div slot="end">{toEuro(itemSum(receipt))}</div>
            </IonItem>
          ))}
          {sum && (
            <IonItem
              detail={true}
              className={css`
                ::part(detail-icon) {
                  visibility: hidden;
                }
              `}
            >
              <div slot="start">Summe</div>
              <div slot="end">{toEuro(sumPrice(receipts))}</div>
            </IonItem>
          )}
        </IonList>
      ) : (
        <InnerMessage>{notFoundMessage}</InnerMessage>
      )}
    </>
  )
}

interface ReceiptDetailFields {
  receiptNumber: boolean
  date: boolean
  time: boolean
  totalPrice: boolean
  totalQuantity: boolean
  payment: boolean
  staff: boolean
}

export const ReceiptDetail: React.FC<{
  receipt: Receipt
  label: string
  fields?: Partial<ReceiptDetailFields>
  barcode?: boolean
}> = ({ receipt, label, fields: customFields = {}, barcode = false }) => {
  useEffect(() => {
    trackUserEvent('receipts:show')
  }, [])

  const fields: ReceiptDetailFields = {
    receiptNumber: true,
    date: true,
    time: false,
    totalPrice: true,
    totalQuantity: false,
    payment: false,
    staff: false,
    ...customFields,
  } as ReceiptDetailFields

  const styles = css`
    line-height: 1.5 !important;

    > tbody > tr:not(:first-of-type) {
      border-top: 1px solid var(--ion-color-light-shade);
    }

    .total {
      border-top: 1px solid #000 !important;
      border-bottom: 1px solid #000;

      td {
        padding: 6px 0;
        text-transform: uppercase;
      }

      .value {
        text-align: right;
        padding-right: 10px;
      }
    }
  `

  return (
    <>
      <div>
        <table className={`table ${styles}`}>
          <tbody>
            {receipt.positions.map((position) => (
              <ReceiptPositionLine
                position={position}
                key={position.receiptPosition}
              />
            ))}
            <tr className="total">
              <td colSpan={2}>Gesamt</td>
              <td className="value">{toEuro(receipt.totalPrice)}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-1">
        <table className="table col-2">
          <tbody>
            {fields.receiptNumber && (
              <tr>
                <td className="label">{label}</td>
                <td>{receipt.receiptNumber}</td>
              </tr>
            )}
            {fields.date && (
              <tr>
                <td className="label">Datum</td>
                <td>{toDate(receipt.date, 'DD.MM.YYYY')}</td>
              </tr>
            )}
            {fields.time && (
              <tr>
                <td className="label">Uhrzeit</td>
                <td>{receipt.time}</td>
              </tr>
            )}
            {fields.totalPrice && (
              <tr>
                <td className="label">Summe</td>
                <td>{toEuro(receipt.totalPrice)}</td>
              </tr>
            )}
            {fields.totalQuantity && (
              <tr>
                <td className="label">Anzahl Artikel</td>
                <td>{receipt.totalQuantity}</td>
              </tr>
            )}
            {fields.payment && (
              <tr>
                <td className="label">Zahlungsart</td>
                <td>
                  {receipt.paymentMethods.map((method, index) => (
                    <Fragment key={index}>
                      {method.description} ({toEuro(method.amount)})
                      <br />
                    </Fragment>
                  ))}
                </td>
              </tr>
            )}
            {fields.staff && (
              <tr>
                <td className="label">Modeberater</td>
                <td>{receipt.staff}</td>
              </tr>
            )}
          </tbody>
        </table>
        {barcode && (
          <div className="mt-2">
            <Barcode value={String(receipt.receiptNumber)} />
          </div>
        )}
      </div>
    </>
  )
}

export const ReceiptPositionLine: React.FC<{ position: ReceiptPosition }> = ({
  position,
}) => {
  const [present, dismiss] = useIonModal(ReceiptPositionModal, {
    position,
    onDismiss: () => dismiss(),
  })

  const styles = css`
    td {
      padding: 2px 0;
    }

    td.amount {
      vertical-align: middle;
      width: 5%;
      font-size: 0.8rem;
    }

    td.image {
      vertical-align: middle;
      width: 15%;
      padding-right: 5px;
      text-align: left;
    }

    table.content {
      .label-price {
        color: var(--ion-color-medium);

        & > span {
          text-decoration: line-through;
        }
      }

      td.description {
        width: 80%;

        & > span {
          border-bottom: 1px dotted var(--ion-color-primary);
        }
      }

      td.value {
        width: 20%;
        text-align: right;
        padding-right: 10px;
      }
    }
  `

  return (
    <tr onClick={() => present()} className={styles}>
      <td className="amount">{position.quantity}×</td>
      <td className="image">
        <img src={productImage(position.ean)} alt="" />
      </td>
      <td>
        <table className="content">
          <tbody>
            <tr>
              <td className="description">
                <span>{position.name}</span>
              </td>
              <td className="value">{toEuro(position.salePrice)}</td>
            </tr>
            <tr>
              <td className="description">
                {position.labelPrice !== position.salePrice && (
                  <div className="label-price">
                    Originalpreis: <span>{toEuro(position.labelPrice)}</span>
                  </div>
                )}
              </td>
            </tr>
            {position.discount !== 0 && (
              <tr>
                <td className="description">
                  Rabatt {toPercent(position.discount / position.salePrice)}
                </td>
                <td className="value">
                  <div className="discount">{toEuro(-position.discount)}</div>
                </td>
              </tr>
            )}
            {position.customerDiscount !== 0 && (
              <tr>
                <td className="description">
                  Rabatt{' '}
                  {toPercent(position.customerDiscount / position.salePrice)}
                </td>
                <td className="value">
                  <div className="discount">
                    {toEuro(-position.customerDiscount)}
                  </div>
                </td>
              </tr>
            )}
            {position.staffDiscount !== 0 && (
              <tr>
                <td className="description">
                  Rabatt{' '}
                  {toPercent(position.staffDiscount / position.salePrice)}
                </td>
                <td className="value">
                  <div className="discount">
                    {toEuro(-position.staffDiscount)}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </td>
      <td>
        <IonIcon color="primary" icon={chevronForwardOutline}></IonIcon>
      </td>
    </tr>
  )
}

const ReceiptPositionModal: React.FC<{
  position: ReceiptPosition
  onDismiss: () => void
}> = ({ position, onDismiss }) => {
  useEffect(() => {
    trackUserEvent('receipts:position')
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss}>Schließen</IonButton>
          </IonButtons>
          <IonTitle>{position && position.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        <>
          <table className="table col-2">
            <tbody>
              <tr>
                <td className="label">Artikel-Nr.</td>
                <td>{position.supplierArticleNumber}</td>
              </tr>
              <tr>
                <td className="label">Beschreibung</td>
                <td>{position.name}</td>
              </tr>
              <tr>
                <td className="label">Größe</td>
                <td>{position.size}</td>
              </tr>
              <tr>
                <td className="label">Menge</td>
                <td>{position.quantity}</td>
              </tr>
              {position.labelPrice !== position.salePrice && (
                <tr>
                  <td className="label">Originalpreis</td>
                  <td className="original-price">
                    {toEuro(position.labelPrice)}
                  </td>
                </tr>
              )}
              <tr>
                <td className="label">Preis</td>
                <td>{toEuro(position.salePrice)}</td>
              </tr>
              {position.discount !== 0 && (
                <tr>
                  <td className="label">Rabatt</td>
                  <td>{toEuro(-position.discount)}</td>
                </tr>
              )}
              {position.customerDiscount !== 0 && (
                <tr>
                  <td className="label">Rabatt</td>
                  <td>{toEuro(-position.customerDiscount)}</td>
                </tr>
              )}
              {position.staffDiscount !== 0 && (
                <tr>
                  <td className="label">Rabatt</td>
                  <td>{toEuro(-position.staffDiscount)}</td>
                </tr>
              )}
            </tbody>
          </table>

          <div>
            <img src={productImage(position.ean)} alt={`${position.ean}`} />
          </div>
        </>
      </IonContent>
    </IonPage>
  )
}

function productImage(ean: number) {
  return `${API}/fashioncloud/products/image/${ean}`
}
