import dayjs from 'dayjs'
import { Receipt } from './api'

// https://stackoverflow.com/a/39914235
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

//
// Formatting date, currency, percent
//

export function toDate(date: string, format: string = 'DD.MM.YYYY'): string {
  return dayjs(date).format(format)
}

export function toEuro(
  cents: number,
  options: Intl.NumberFormatOptions = {}
): string {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    ...options,
  })
  return formatter.format(cents)
}

export function toPercent(
  ratio: number,
  options: Intl.NumberFormatOptions = {}
): string {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'percent',
    maximumSignificantDigits: 2,
    ...options,
  })
  return formatter.format(ratio)
}

//
// Filtering and calculating receipt payments
//

export function hasPayment(receipt: Receipt, description: string): boolean {
  return !!filterPayments(receipt.paymentMethods, description).length
}

export function paymentAmount(receipt: Receipt, description: string) {
  return filterPayments(receipt.paymentMethods, description).reduce(
    (sum, m) => sum + m.amount,
    0
  )
}

export function filterPayments(
  methods: Receipt['paymentMethods'],
  description: string
): Receipt['paymentMethods'] {
  return methods.filter((m) => m.description === description)
}
