import { useEffect } from 'react'
import { App } from '@capacitor/app'

export function useOnResume(handler: () => void) {
  useEffect(() => {
    // See https://capacitorjs.com/docs/apis/app#addlistenerresume-
    const resumeListenerHandle = App.addListener('resume', handler)
    return () => {
      resumeListenerHandle.then((h) => h.remove())
    }
  }, [handler])
}
