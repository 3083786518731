import {
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonButton,
} from '@ionic/react'
import { TENANT } from '../config'
import Header from '../components/Header'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const VouchersPage: React.FC = () => {
  const tenantConfig = useTenantConfig()

  return (
    <IonPage>
      <IonHeader>
        <Header menu={true} back={!tenantConfig?.tabs.vouchers.enabled}>
          {tenantConfig?.tabs.vouchers.label}
        </Header>
      </IonHeader>
      <IonContent fullscreen>
        <img
          src={`/assets/tenants/${TENANT}/images/header-vouchers.jpg`}
          alt={tenantConfig?.tabs.vouchers.label}
        />
        <div className="ion-padding-horizontal ion-padding-top">
          <IonText>
            Das besondere Geschenk mit Stil. Überraschen Sie einen lieben
            Menschen mit einem Geschenkgutschein von{' '}
            {tenantConfig?.about.shortName}. Vom Rock bis zur Bluse, von den
            Socken bis zur Krawatte, vom T-Shirt bis zu hochwertigen Pullovern.
            Versenden Sie den Geschenkgutschein direkt per E-Mail oder drucken
            Sie diesen aus, um das Geschenk persönlich zu überreichen.
          </IonText>
        </div>
        <div className="mt-1 ion-padding-horizontal">
          <IonButton
            href={tenantConfig?.tabs.vouchers.link}
            target="_blank"
            expand="block"
          >
            Jetzt zum Gutscheinportal wechseln
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default VouchersPage
