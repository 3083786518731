import { ReactNode, HTMLAttributes } from 'react'
import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { Controller, UseControllerProps } from 'react-hook-form'
import { delegateDomInput } from './utils'
import { Errors } from './Errors'

const INPUT_MODES: {
  [index: string]: HTMLAttributes<HTMLIonInputElement>['inputMode']
} = {
  number: 'decimal',
  email: 'email',
}

const TextInput = <T,>({
  control,
  rules,
  name,
  label,
  labelPosition = 'floating',
  placeholder,
  type = 'text',
  disabled = false,
  children,
}: {
  label: string
  labelPosition?: 'floating' | 'fixed' | 'stacked'
  placeholder?: string
  type?: HTMLIonInputElement['type']
  disabled?: boolean
  children?: ReactNode
} & UseControllerProps<T>) => {
  const inputmode = INPUT_MODES[type]

  return (
    <IonItem>
      <IonLabel position={labelPosition}>{label}</IonLabel>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({
          field: { onChange, onBlur, value, ref },
          formState: { errors },
        }) => (
          <>
            <IonInput
              onIonChange={onChange}
              onIonBlur={onBlur}
              value={value}
              ref={(ionElem) => delegateDomInput(ref, ionElem)}
              type={type}
              inputmode={inputmode}
              placeholder={placeholder}
              disabled={disabled}
            ></IonInput>
            {children}
            <Errors errors={errors} name={name} />
          </>
        )}
      ></Controller>
    </IonItem>
  )
}

export default TextInput
