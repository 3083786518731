import { useEffect, useRef, useState } from 'react'
import JsBarcode from 'jsbarcode'
import * as Sentry from '@sentry/capacitor'
import { css } from '@emotion/css'
import { useDoubleTap } from 'use-double-tap'
import { InnerMessage } from './Content'
import { useTenantConfig } from '../lib/providers/tenantConfig'

const Barcode: React.FC<{
  value: string
}> = ({ value }) => {
  const tenantConfig = useTenantConfig()
  const barcodeOptions = tenantConfig!.features.barcode

  // `customerCardNumber` (a string) may contain leading zeros
  const normalizedValue = barcodeOptions.stripLeadingZeros
    ? stripLeadingZeros(value)
    : value

  const [error, setError] = useState<boolean>(false)
  const [displayValue, setDisplayValue] = useState<boolean>(
    barcodeOptions.displayValue
  )

  const svg = useRef<SVGSVGElement>(null)

  useEffect(() => {
    if (!normalizedValue) {
      console.log(`Invalid barcode value ${normalizedValue}`)
      setError(true)
      return
    }

    try {
      JsBarcode(svg.current, normalizedValue, {
        ...barcodeOptions,
        displayValue,
      })
      setError(false)
    } catch (err) {
      console.warn(`Could not render barcode value ${normalizedValue}`)
      console.error(err)
      Sentry.captureException(err)
      setError(true)
    }
  }, [barcodeOptions, displayValue, svg, normalizedValue])

  const { onClick: onDoubleTap } = useDoubleTap((event) => {
    if (event.shiftKey) {
      setDisplayValue(!displayValue)
    }
  }, 1000)

  return (
    <div
      // Easter egg (and for debugging purposes)
      onClick={onDoubleTap}
      className={css`
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        min-height: 120px;

        svg {
          position: static;
          display: ${error ? 'none' : 'block'};
          margin: 0 auto;
          max-width: 90vw; // Ensure barcode image does not overflow viewport
        }

        svg > g > rect {
          fill: var(--app-barcode-color) !important;
        }
      `}
    >
      <svg ref={svg}></svg>
      {error && (
        <InnerMessage>
          <small>Barcode ({normalizedValue}) ungültig.</small>
        </InnerMessage>
      )}
    </div>
  )
}

function stripLeadingZeros(number: string) {
  // Get numerical value, then convert back to string. See https://stackoverflow.com/a/6676606.
  return (+number).toString()
}

export default Barcode
