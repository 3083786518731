import { useIonAlert } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { Capacitor } from '@capacitor/core'
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications'
import { useAuth } from '../providers/auth'
import { trackAppEvent, trackUserEvent } from '../analytics'
import storage from '../storage'
import { postToken } from '../api'
import { useEffect } from 'react'

export function usePushNotification() {
  const [present] = useIonAlert()
  const history = useHistory()
  const auth = useAuth()

  useEffect(() => {
    const trackPushNotificationAction = (path?: string | null) => {
      const loggedIn = !!auth.user
      const track = loggedIn ? trackUserEvent : trackAppEvent
      track('push:action', { props: { pushActionPath: path } })
    }

    return setupPushListeners({
      // User receives a notification while the app is in the foreground.
      pushNotificationReceived: (notification: PushNotificationSchema) => {
        present({
          header: notification.title,
          message: notification.body,
          buttons: getNotificationButtons(notification, {
            onConfirm: (path) => {
              if (path) {
                history.push(path)
              }
              trackPushNotificationAction(path)
            },
          }),
        })
      },
      // User performs an action on a notification.
      pushNotificationActionPerformed: (path: string) => {
        if (path) {
          history.push(path)
        }
        trackPushNotificationAction(path)
      },
    })
  }, [auth.user, history, present])

  useEffect(() => {})

  useEffect(() => {
    ;(async () => {
      await registerPushIfAccepted()
    })()
  }, [])
}

function setupPushListeners({
  pushNotificationReceived,
  pushNotificationActionPerformed,
}: {
  pushNotificationReceived: (notification: PushNotificationSchema) => void
  pushNotificationActionPerformed: (type: string) => void
}): () => void {
  // DEBUG: Uncomment to simulate receiving notifications (e.g. in web view)
  // debug()

  if (!isPushPluginAvailable()) {
    // Nothing to cleanup
    return () => {}
  }

  // On success, we should be able to receive notifications
  const registrationHandle = PushNotifications.addListener(
    'registration',
    async (token: Token) => {
      console.log('Push registration success, token: ' + token.value)
      const { clientId } = await storage.getCredentials()
      postToken(clientId, token.value)
    }
  )

  // Some issue with our setup and push will not work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const registrationErrorHandle = PushNotifications.addListener(
    'registrationError',
    (error: unknown) => {
      console.log('Error on registration: ' + JSON.stringify(error))
    }
  )

  // Show us the notification payload if the app is open on our device
  const pushNotificationReceivedHandle = PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      console.log('Push notification received: ' + JSON.stringify(notification))
      pushNotificationReceived(notification)
    }
  )

  // Method called when tapping on a notification
  const pushNotificationActionPerformedHandle = PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (action: ActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(action))
      const path = getNotificationTargetPath(action.notification.data.type)
      pushNotificationActionPerformed(path)
    }
  )

  return () => {
    Promise.all([
      registrationHandle,
      registrationErrorHandle,
      pushNotificationReceivedHandle,
      pushNotificationActionPerformedHandle,
    ]).then((handles) => {
      handles.forEach((handle) => handle.remove())
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function debug() {
    const testNotification: PushNotificationSchema = {
      id: '0',
      title: 'Test',
      data: { type: 'birthday-voucher' },
    }
    pushNotificationReceived(testNotification)
  }
}

// If user has accepted notifications before, register on initialization.
async function registerPushIfAccepted() {
  if (!isPushPluginAvailable()) {
    return
  }

  if (await storage.isNotificationsAccepted()) {
    await registerForPushNotifications()
  }
}

// Request permission and register for push notifications
export async function registerForPushNotifications(): Promise<boolean> {
  if (!isPushPluginAvailable()) {
    return true
  }

  let permissionStatus = await PushNotifications.checkPermissions()

  if (permissionStatus.receive === 'prompt') {
    permissionStatus = await PushNotifications.requestPermissions()
  }

  if (permissionStatus.receive === 'granted') {
    // Register with Apple / Google to receive push via APNS/FCM. Note this may
    // fail. We return true anyway (because user accepted), but no `registration` event
    // will be emitted in this case.
    await PushNotifications.register()
    return true
  } else {
    return false
  }
}

function isPushPluginAvailable() {
  return Capacitor.isPluginAvailable('PushNotifications')
}

function getNotificationButtons(
  notification: PushNotificationSchema,
  { onConfirm }: { onConfirm: (page: string | null) => void }
) {
  const targetPath = getNotificationTargetPath(notification.data.type)

  const actionButton = {
    text: 'Anzeigen',
    handler: () => {
      onConfirm(targetPath)
    },
  }

  const ignoreButton = targetPath ? 'Ignorieren' : 'Okay'

  return targetPath ? [ignoreButton, actionButton] : [ignoreButton]
}

const NOTIFICATION_TARGETS = {
  news: '/news',
  events: '/events',
  'birthday-voucher': '/my/vouchers',
  'voucher-bonus': '/my/vouchers',
} as { [key: string]: string }

function getNotificationTargetPath(notificationType: string) {
  return NOTIFICATION_TARGETS[notificationType]
}
